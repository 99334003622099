// @flow
import './Tag.scss'
import * as React from 'react'
import { withParams } from '@upgrowth/reactkit'
import { add, map, range, trim, isEmpty, keys} from 'lodash'
import { SelectTagEditor, SelectTagViewer } from './SelectTag'
import { MultiSelectTagEditor, MultiSelectTagViewer } from './MultiSelectTag'
import { ScaleTagEditor, ScaleTagViewer } from './ScaleTag'
import { TextTagEditor, TextTagViewer } from './TextTag'
import { NumberTagEditor, NumberTagViewer } from './NumberTag'
import { MultiUserSelectTagEditor, MultiUserSelectTagViewer } from './MultiUserSelectTag'
import type { Project } from '../../database'

export type Tag = {
    name: string,
    type: string,
}
export type TagWithKey = {
    key: string,
} & Tag

export type Tags = {
    [string]: Tag
}
export type Entities ={
    [string]: Entity
}
export type Entity = {
    name: string,
    description: string,
    image: {
        mediaUrl: string,
        storageUrl: string
    },
    createdAt: string,
    type: string,
    tags: Tags
}
export type EntityWithKey = {
    key: string,
} & Entity

export type TagType = {
    name: string,
    type: string,
    data: string,
    multiple: boolean,
    discrete: boolean,
    potentialValues: (tag: Tag) => string[]
}
export const NO_TAG_VALUE = {value: "__no_tag_value", label: "No Value"}

export type PotentialValue = (tag: Tag, project: Project) => ?Array<{value: string, label: string}>

export const TagTypes: {[string]: TagType} = {
    string: {
        name: 'Text',
        type: 'string',
        data: "string",
        multiple: false,
        discrete: false,
        potentialValues: (tag: Tag) => undefined
    },
    percent: {
        name: 'Percentage',
        type: 'percent',
        data: "number",
        multiple: false,
        discrete: false,
        potentialValues: (tag: Tag) => undefined
    },
    scale: {
        name: 'Scale',
        type: 'scale',
        data: "number",
        multiple: false,
        discrete: true,
        potentialValues: (tag: Tag) => [NO_TAG_VALUE, ...range(tag.from, parseInt(tag.to)+1).map((val)=>({value:""+val, label: ""+val}))]
    },
    select: {
        name: 'Select',
        type: 'select',
        data: "string",
        multiple: false,
        discrete: true,
        potentialValues: (tag: Tag) => [NO_TAG_VALUE, ...map(tag.options, value => ({label: value, value}))]
    },
    multiselect: {
        name: 'Multi-Select',
        type: 'multiselect',
        data: "array",
        multiple: true,
        discrete: true,
        potentialValues: (tag: Tag) => [NO_TAG_VALUE, ...map(tag.options, value => ({label: value, value}))]
    },
    user: {
        name: 'Users',
        type: 'user',
        data: "array",
        multiple: true,
        discrete: true,
        potentialValues: (tag: Tag, project: Project) => {
            console.log("Tag optiojns ", tag)
            return [NO_TAG_VALUE, ...map(project.users, (user, key) => ({label: user, value: key}))]
        }
    }
}

const TagViewers = {
    'select': SelectTagViewer,
    'multiselect': MultiSelectTagViewer,
    'user': MultiUserSelectTagViewer,
    'scale': ScaleTagViewer,
    'string': TextTagViewer,
    'percent': NumberTagViewer
}
const TagEditors = {
    'select': SelectTagEditor,
    'multiselect': MultiSelectTagEditor,
    'user': MultiUserSelectTagEditor,
    'scale': ScaleTagEditor,
    'string': TextTagEditor,
    'percent': NumberTagEditor
}

const TagEditor = ({tag = {type: ""}, value, onChange, ...props}: { tag: Tag, value: any, onChange: (value: any) => any, }) => {
    const Editor = TagEditors[tag.type] || "div"
    return (
        <Editor tag={tag} value={value} onChange={onChange} {...props}/>
    )
}


const TagViewer = ({ className, tag  = {type: ""}, value}: { className: string, tag: Tag, value: any }) => {
    // TODO - isEmpty returns true on booleans - sof if we add boolean types we'll need to update this line
    if(isEmpty(value)) {
        return <TextTagViewer className={className} value="Not Set" />
    }

    const Viewer = TagViewers[tag.type] || "div"
    return  (
        <Viewer className={`Tag TagViewer ${className}`} tag={tag} value={value} />
    )
}

export {TagEditor, TagViewer}
