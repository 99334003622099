// @flow

import React from 'react'
import { Button, Flex, Form, Row } from '@upgrowth/react-fulcrum'
import { momentUpdater, updateMomentTag } from '../../services/moments'
import { resolveRoute } from '../../routes'
import type { Moment as MomentType, UserRole } from '../../database'
import type { History } from 'react-router-dom'
import './Moment.scss'
import { TagEditor, TagViewer } from '../../elements/tags/Tag'
import { chain, get, isEmpty, pickBy } from 'lodash'
import AddTag from '../../components/tags/AddTag'
import Loading from '../../components/Loading'
import MomentCoordinates from '../../components/research/MomentCoordinates'
import { hasEditorRole } from '../../roles'

type Props = {
    momentId: string,
    moment: MomentType,
    researchId: string,
    history: History,
    projectId: string,
    userRole: UserRole,
}

const Moment = ({momentId, moment, researchId, history, projectId, showTutorial, tags = {}, userRole}: Props) => {
    if(isEmpty(moment)){
        return <div className='Moment'><Loading/></div>
    }
    const editable = hasEditorRole(userRole)
    const update = momentUpdater(momentId, researchId, moment, projectId)
    const updateTag = (attrKey, value) => updateMomentTag(momentId, researchId, projectId, attrKey, value)
    const goToInterview = () => history.push(resolveRoute('moments', {projectId, researchId}))
    const availableTags = pickBy(tags, (_, key) => moment.tags === undefined || moment.tags[key] === undefined)
    const {coords} = moment
    const Editor = editable ? TagEditor : TagViewer
    const tagFields = chain(moment.tags)
        .map((value, tagKey) => ({
            name: `tags.${tagKey}`,
            label: get(tags, [tagKey, 'name']),
            value,
            render: (value, onChange) => <Row style={{alignItems: 'center'}}>
                <Editor tag={tags[tagKey]} value={value} onChange={onChange} />
                {editable && <a href="javascript:" onClick={() => updateTag(tagKey, null)}>Remove</a>}
            </Row>
        }))
        .sortBy('label')
        .value()


    return (
        <div className='Moment'>
            {showTutorial &&
            <div>
                <p>
                    Now that you’ve defined your first moment, you can begin to synthesise the data by transcribing what
                    is said, forming an insight and adding tags.
                </p>
                <p>
                    You can keep looping the video using the <em>loop</em> button until you’ve captured everything that
                    is said. Extend or reduce the length by moving the sliders on either end.
                </p>
            </div>

            }

            <Form onChange={update}
                  value={moment}
                  fields={[
                      {
                          name: 'name',
                          type: 'text',
                          label: 'Moment name',
                          readOnly: !editable
                      },
                      {
                          label: "Timecode",
                          render: ()=> <MomentCoordinates moment={moment}/>
                      },
                      {
                          name: 'description',
                          type: 'textarea',
                          label: 'Detail',
                          readOnly: !editable,
                          placeholder: "Transcription, Quotes or Notes"
                      },
                      ...tagFields
                  ]}>
              {editable && <AddTag tags={availableTags} onSelect={(tag) => updateTag(tag, "")}/>}
            </Form>
            <Flex/>
            <Button type='button' onClick={goToInterview}>Done</Button>
        </div>
    )
}

export default Moment
