// @flow

import './App.scss'
import * as React from 'react'
import { Redirect, Route, Routes, Switch } from '@upgrowth/reactkit'
import { BrowserRouter } from 'react-router-dom'
import routes from './routes'
import LoggedIn from './containers/LoggedIn'
import LogIn from './pages/LogIn'
import AutoSubscriber from './components/AutoSubscriber'
import Admin from './admin/Admin'
import AboutPage from './pages/about/AboutPage'
import ProjectRoutes from './components/projects/ProjectRoutes'

import { TrackPageView } from './services/tracking/TrackPageView'

const App = () => (
  <BrowserRouter>
    <TrackPageView>
      <Routes routes={routes}>
        <div className='App'>
            <LoggedIn loggedOut={<Switch>
                <Route name='about' component={AboutPage}/>
                <Route name='home' exact component={AboutPage}/>
                <Route component={LogIn}/>
            </Switch>}>
            <>
              <AutoSubscriber />
              <Switch>
                <Route name='admin' component={Admin} />
                <Route component={ProjectRoutes} />
              </Switch>
            </>
          </LoggedIn>
        </div>
      </Routes>
    </TrackPageView>
  </BrowserRouter>
)

export default App
