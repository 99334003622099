// @flow

import * as React from 'react'
import Modal from 'react-modal'
import './Modal.scss'

Modal.setAppElement('#root')

const CustomModal = ({isOpen, onRequestClose, actions, title, children}) => (
  <Modal isOpen={!!isOpen}
         className='CustomModal'
         style={{overlay: {}, content: {}}}
         overlayClassName='CustomModal-overlay'
         onRequestClose={onRequestClose}>
    {title && <h3>{title}</h3>}
    {children}
    <div className='actions'>
      {actions}
    </div>
  </Modal>
)

export default CustomModal
