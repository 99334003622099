// @flow
import './TagList.scss'
import * as React from 'react'
import { map, trim } from 'lodash'
import type { Tags } from '../../elements/tags/Tag'

const TagRow = ({id, tag, onRemove, onEdit}) => {
    const {name, type, ...data}= tag
    return (
        <div className="TagRow">
            <div>{name} ({type})</div>
            {onEdit && <a href="javascript:" onClick={()=>onEdit(id)}>Edit</a> }
            {onRemove && <a href="javascript:" onClick={()=>onRemove(id)}>Remove</a> }
        </div>
    )
}
export const TagList = ({tags, onEdit, onRemove}: { tags: Tags, onRemove: (key: string)=>any }) => (
    <div className={`TagList ${(onEdit || onRemove) ? 'editing' : '' }`}>
        {map(tags, (tag, key) => (
            <TagRow key={key} id={key} tag={tag} onEdit={onEdit} onRemove={onRemove}/>
        ))}
    </div>
)
