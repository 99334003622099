import mixpanel from 'mixpanel-browser'

/**
 * Mixpanel tracking strategy
 * @type {{init: ((p1?:*)), page: ((p1:string)), event: ((p1:string, p2:any)), create: ((p1:any)), identify: ((p1:any)), anonymise: (())}}
 */


function interestingPartsOfUser(user) {
  const {email, displayName, phoneNumber, photoURL, uid} = user
  return {'$email': email, '$name': displayName, '$phone': phoneNumber, photoURL, '$distinct_id': uid}
}

export default class MixpanelStrategy {

  // TODO - Mixpanel has heaps of great stuff on people, would be good to extend this basic
  // API to track user info (industry, gender, etc)

  init (trackingId) {
    if (trackingId) {
      console.log('Initialising Mixpanel with ', trackingId)
      mixpanel.init(trackingId, {
        track_pageview: false,
        property_blacklist: ['password', 'confirm_password']
      })
    } else {
      console.log('No Mixpanel tracking ID configured')
    }
  }

  page (page: string) {
    mixpanel.track('Page View')
  }

  event (name: string, event: Event) {
    mixpanel.track(name, event)
  }

  create (user: User) {
    mixpanel.alias(user.uid)
    mixpanel.people.set(interestingPartsOfUser(user))
  }

  identify (user: User) {
    mixpanel.identify(user.uid)
    mixpanel.people.set(interestingPartsOfUser(user))
  }

  anonymise () {
    mixpanel.reset()
  }
}
