// @flow

import * as React from 'react'
import { debounce, forEach, map } from 'lodash'
import { RouteObserver } from '@upgrowth/reactkit'
import { Event, User } from './TrackingTypes'

/**
 * Google Analytics tracking strategy
 * @type {{init: ((p1?:*)), page: ((p1:string)), event: ((p1:string, p2:any)), create: ((p1:any)), identify: ((p1:any)), anonymise: (())}}
 */
export default class GaTrackingStrategy {
  trackingId = undefined

  init (trackingId) {
    const {gtag} = window
    this.trackingId = trackingId
    if (gtag && trackingId) {
      console.log('Initialising GA with ', trackingId)
      gtag('config', trackingId)
      // ga('create', trackingId, 'auto');
      // gtag('send', 'pageview');
    } else {
      console.log('No GA tracking ID configured')
    }
  }

  page (page: string) {
    const {gtag} = window
    if (gtag) {
      // this is how the guide suggests tracking page views
      gtag('config', this.trackingId, {'page_path': page})
    }
  }

  event (name: string, event: Event) {
    const {gtag} = window
    if (gtag) {
      gtag('event', name, {'event_category': event.category, 'event_label': event.label, 'event_value': event.value})
    }
  }

  create (user: User) {
    const {gtag} = window
    if (gtag) {
      gtag('event', 'sign_up', {method: 'Email'})
    }
  }

  identify (user: User) {
    const {gtag} = window
    if (gtag) {
      gtag('event', 'login', {method: 'Email'})
    }
  }

  anonymise () {
    const {gtag} = window
    if (gtag) {
      gtag('event', 'logout', {method: 'Email', category: 'engagement'})
    }
  }
}
