// @flow

import './ResearchReport.scss'
import * as React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { intersection, isEmpty, map, size } from 'lodash'
import { Button, Modal } from '@upgrowth/react-fulcrum'
import TagView from '../../components/tags/TagView'
import Page from '../../compositions/Page'
import CreateResearchItem from '../../compositions/research/CreateResearchItem'
import { resolveRoute } from '../../routes'
import Loading from '../../components/Loading'
import { deleteInterview, updateTagOnInterview } from '../../services/interviews'
import { withProject } from '../../services/withProject'
import { hasEditorRole } from '../../roles'

const ResearchInstructions = ({onClick, canUpload}) => (
    <div className="introduction">
        <h1>Research</h1>
        <div>
            <p>
                Welcome to the research section of Forest.
            </p>
            <p>
                This is where you and your team can collect information and assets that you gather
                during research, interviews, workshops and meetings.
            </p>
            <p>
                To get started, upload your first research asset.
            </p>
        </div>
      {canUpload  && <Button onClick={onClick}>Upload Research Item</Button>}
    </div>
)

class ResearchReport extends React.Component<*> {
    props: {
        projectId: string,
        insights: any[],
        history: any
    };
    state: {
        create: boolean,
        working: boolean,
    } = {
        working: false,
        create: false,
    };

    handleAdd = () => this.setState({create: true})

    handleItemTagChange = async (key, tag, value) => {
        const {projectId} = this.props;
        await updateTagOnInterview(projectId, key, tag, value)
    }

    viewResearchItem = (researchId: string) => {
        const {history, projectId} = this.props
        history.push(resolveRoute('researchItem', {projectId, researchId}))
    }

    deleteResearchItem = async (researchId) => {
        const {projectId} = this.props
        if (window.confirm("Are you sure you want to delete this research item?")) {
            await deleteInterview(researchId, projectId)
        }
    }

    itemCreated = (researchItem: string)=>{
      this.setState({create: false})
      this.viewResearchItem(researchItem)
    }

    render() {
        const {project: {research = {}, tags = {}}, projectId, loading, userRole} = this.props;
        const {working, create} = this.state;
      const editable = hasEditorRole(userRole)

      const content = isEmpty(research) ?
            <ResearchInstructions onClick={this.handleAdd} canUpload={editable}/>
            : <TagView
                className="inset"
                tags={tags}
                items={research}
                onAdd={editable && this.handleAdd}
                onClick={this.viewResearchItem}
                onChange={editable && this.handleItemTagChange}
                name="Research"
                rowActions={editable &&
                    [
                        { label: "View", action: this.viewResearchItem},
                        { label: "Delete", action: this.deleteResearchItem},
                    ]
                }
            />

        return (
            <Page className={"ResearchReport"}>
                {loading ? <Loading/> : content}
                <Modal isOpen={create} onRequestClose={() => this.setState({create: false})}>
                    <CreateResearchItem projectId={projectId} onComplete={this.itemCreated} tags={tags}/>
                </Modal>
            </Page>
        )
    }
}

export default withParams(withProject(ResearchReport))