// @flow

import './SegmentTags.scss'
import * as React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { firekit } from '@upgrowth/firekit'
import { map, mapValues, omitBy, trim } from 'lodash'
import type { Tags } from '../../elements/tags/Tag'
import AddTag from '../../components/tags/AddTag'
import { TagList } from '../../components/tags/TagList'
import Page from '../../compositions/Page'
import { Button, FormRow, Switch } from '@upgrowth/react-fulcrum'
import RenderEntity from '../../compositions/entity/RenderEntity'
import { resolveRoute } from '../../routes'

class SegmentTags extends React.Component<*> {
  props: {
    projectId: string,
    segmentId: string,
    segment: {},
    tags: Tags,
    history: any
  }
  state: {
    working: boolean,
    current: string,
    editMode: boolean,
    confirmDelete: Boolean
  } = {
    working: false,
    current: '',
    editMode: false,
    confirmDelete: null
  }

  updateTag = async (key, tag, value) => {
    const {projectId, segmentId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    const path = `projects/${projectId}/segments/${segmentId}/${key}/tags/${tag}`
    await database.ref(path).set(value)
    this.setState({working: false})
  }

  addTag = async (tag: string) => {
    const {projectId, segmentId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    await database.ref(`projects/${projectId}/segments/${segmentId}/tags`).update({[tag]: tag})
    this.setState({working: false})
  }

  removeTag = async (tag: string) => {
    const {projectId, segmentId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    await database.ref(`projects/${projectId}/segments/${segmentId}/tags/${tag}`).set(null)
    this.setState({working: false})
  }

  updateSegment = async (value) => {
    const {projectId, segmentId} = this.props
    const {database} = firekit()
    this.setState({working: true})

    await database.ref(`projects/${projectId}/segments/${segmentId}`).update({
      name: value.name,
      description: value.description
    })
  }

  confirmDeleteSegment = () => {
    this.setState({confirmDelete: true})
  }

  deleteSegment = async () => {
    const {projectId, segmentId, history} = this.props
    const {database} = firekit()
    this.setState({working: true})
    await database.ref(`projects/${projectId}/segments/${segmentId}`).remove()
    history.push(resolveRoute('segments', {projectId}))
  }

  cancelDelete = () => {
    this.setState({confirmDelete: false})
  }

  toggleEditMode = () => {
    this.setState({editMode: !this.state.editMode})
  }

  render () {
    const {segment = {}, tags = {}} = this.props
    const {editMode, confirmDelete} = this.state
    const segmentTags = segment.tags || {}
    const tagsOnSegment = mapValues(segmentTags, (_, key) => tags[key])
    const tagsNotOnSegment = omitBy(tags, (value, key) => segmentTags[key])

    return (
      <Page className="SegmentTags" size="small">
        <Switch className="edit-mode-toggle" value={editMode} onChange={this.toggleEditMode}
                label="Edit"
                variant="light" />

          <RenderEntity entity={segment} onChange={this.updateSegment} isEditing={editMode}>
            <TagList tags={tagsOnSegment} onRemove={this.removeTag} />
            {editMode &&
              <React.Fragment>
                <AddTag tags={tagsNotOnSegment} onSelect={this.addTag} />
                <FormRow>
                  {confirmDelete ?
                    <React.Fragment>
                      Are you sure? <Button onClick={this.deleteSegment}>Yes, go ahead</Button> <Button onClick={this.cancelDelete}>No, cancel</Button>
                    </React.Fragment>
                    :
                    <Button onClick={this.confirmDeleteSegment}>Delete {segment.name}</Button>
                  }
                </FormRow>
              </React.Fragment>
            }
          </RenderEntity>
      </Page>
    )
  }
}

export default withParams(SegmentTags)
