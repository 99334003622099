// @flow

import { firekit } from '@upgrowth/firekit'
import type { Moment, MomentType } from '../database'
import moment from 'moment'
import { debounce, difference, keys, mapKeys, mapValues, padStart, values } from 'lodash'
import track from './tracking/track'

export const createMoment = async (projectId: string, researchId: string, data: {timeStart: number, timeEnd: number, fileId: string, type: MomentType}) => {
    const {timeStart, timeEnd, fileId} = data
    const {database, TIMESTAMP, auth} = firekit()
    const moment: Moment = {
        name: `${toTimeCode(timeStart)} - ${toTimeCode(timeEnd)}`,
        type: 'moment',
        projectId,
        researchId,
        timeStart,
        timeEnd,
        fileId,
        createdAt: TIMESTAMP,
        createdBy: auth.currentUser.uid
    }

    const push = database.ref(`/projects/${projectId}/moments`).push(moment)
    const {key} = push
    await database.ref(`/projects/${projectId}/research/${researchId}/moments`).update({[key]: key})
    await push
    const duration = timeEnd - timeStart

    track.event('Create', {category: 'Moments', label: projectId, duration, value: duration})

    return key
}

function pad(n) {
    return padStart(n, 2, '0')
}

export function toTimeCode(seconds: number): string {
    const d = moment.duration(seconds, 'seconds')
    return `${pad(d.hours())}:${pad(d.minutes())}:${pad(d.seconds())}`
}

export function toDurationString(seconds: number): string {
    const d = moment.duration(seconds, 'seconds')
    return d.humanize()
}

const trackMomentUpdate = debounce((projectId, duration) =>
    track.event('Update', {category: 'Moments', label: projectId, duration, value: duration}), 5000)

export const momentUpdater = (momentId, researchId, moment: Moment, projectId: string) => {
    const {database} = firekit()
    const ref = database.ref(`/projects/${projectId}/moments/${momentId}`)
    return (momentUpdates: $Shape<Moment>) => {
        const duration = (momentUpdates.timeEnd || moment.timeEnd) - (momentUpdates.timeStart || moment.timeStart)
        trackMomentUpdate(projectId, duration)
        return ref.update(momentUpdates)
    }
}
export const updateMomentTag = (momentId: string, researchId: string, projectId: string, tag: string, value: any) => {
    const {database} = firekit()
    return database.ref(`/projects/${projectId}/moments/${momentId}/tags/${tag}`).set(value)
}

export const trackBeginMomentCapture = (ratio: number, projectId: string) =>
    track.event('Begin Capture', {category: 'Moments', label: projectId, ratio, value: ratio})

export const trackCancelMomentCapture = (ratio: number, projectId: string) =>
    track.event('Cancel Capture', {category: 'Moments', label: projectId, ratio, value: ratio})

export const deleteMoment = async (momentId, researchId, projectId) => {
    return Promise.all([
        firekit().database.ref(`/projects/${projectId}/moments/${momentId}`).remove(),
        firekit().database.ref(`/projects/${projectId}/research/${researchId}/moments/${momentId}`).remove(),
        track.event('Delete', {category: 'Moments', label: projectId})
    ])
}
