// @flow

import './SiteSection.scss'
import * as React from 'react'

const SiteSection = ({as = "div", className = "", ...props}) => {
    const As = as
    return (
        <As className={`SiteSection ${className}`} {...props}/>
    );
}


export default SiteSection
