// @flow

import './Segments.scss'
import * as React from 'react'
import { NavLink, Route, Switch, withParams } from '@upgrowth/reactkit'
import { map, trim } from 'lodash'
import { Button, Flex, Modal } from '@upgrowth/react-fulcrum'
import Segment from './Segment'
import CreateSegment from '../../compositions/segments/CreateSegment'
import Card from '../../components/Card'
import CardGrid from '../../compositions/CardGrid'
import Page from '../../compositions/Page'
import { FiPlus } from 'react-icons/fi'
import type { UserRole } from '../../database'
import { hasEditorRole } from '../../roles'

class SegmentsOverview extends React.Component<*> {
  props: {
    projectId: string,
    segments: {},
  }
  state: {
    create: boolean,
  } = {
    create: false
  }

  closeModal = () => {
    this.setState({create: false})
  }
  openModal = () => {
    this.setState({create: true})
  }

  render () {

    const {segments, projectId, userRole} = this.props
    const {working, create} = this.state
    const editable = hasEditorRole(userRole)

    return (
      <Page className="SegmentsOverview">
        <h1>Segments</h1>
        <CardGrid>
          {map(segments, ({name = 'Segment', profiles}, segmentKey) => (
            <Card className="segment" key={segmentKey}>
              <h3>{name}</h3>
              <Flex />
              <Button as={NavLink} name="segment" projectId={projectId} segmentId={segmentKey} key={segmentKey}>
                View {name}
              </Button>
            </Card>
          ))}
          {editable && <Card onClick={this.openModal}>
            <h3>Create</h3>
            <div className="card-icon">
              <FiPlus />
            </div>
          </Card>
          }
        </CardGrid>

        <Modal isOpen={create} onRequestClose={this.closeModal}>
          <CreateSegment projectId={projectId} onComplete={this.closeModal} />
        </Modal>
      </Page>
    )
  }
}

export default SegmentsOverview
