// @flow

import * as React from "react"
import {map} from "lodash"
import {MultiSelectTagEditor, MultiSelectTagViewer} from "./MultiSelectTag";
import {firebind} from "@upgrowth/firekit";
import {withParams} from "@upgrowth/reactkit";

const withUsers = (component) => {
  return withParams(firebind(component, {
    users: ({projectId}) => `projects/${projectId}/users`
  }))
}

const MultiUserSelectTagEditor = withUsers(({ users, ...props }) => {
  const options = map(users, (name, id) => ({label: name, value: id}))
  return <MultiSelectTagEditor {...props} options={options} />
})

const MultiUserSelectTagViewer = withUsers(({value = [], users = [], ...props}) => {
  const values = map(value, (userId) => users[userId])
  return <MultiSelectTagViewer {...props} value={values}  />
})

export { MultiUserSelectTagEditor, MultiUserSelectTagViewer }