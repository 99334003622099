// @flow

import './Insights.scss'
import * as React from 'react'
import { NavLink, Route, Switch, withParams } from '@upgrowth/reactkit'
import { intersection, map, size } from 'lodash'
import InsightsReport from './InsightsReport'
import InsightEditor from './InsightEditor'
import SiteSection from '../../compositions/SiteSection'

class Insights extends React.Component<*> {

    render() {
        const {projectId} = this.props;

        return (
            <SiteSection className="Insights">
                <Switch>
                    <Route name='insights' exact component={InsightsReport}/>
                    <Route name='insightEditor' component={InsightEditor}/>
                </Switch>
            </SiteSection>
        )
    }
}

export default withParams(Insights)