// @flow
import './Tabs.scss'
import * as React from 'react'

const Tabs = ({className = "", children, as = "div", ...props}: { className?: string, children: React.Children, as: React.Node }) => {
    const As = as
    return (
        <As className={`Tabs ${className}`} {...props}>
            {children}
        </As>
    )
}
export default Tabs