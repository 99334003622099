// @flow
import './TagBoard.scss'
import * as React from 'react'
import { castArray, chain, each, every, find, first, forEach, get, groupBy, includes, indexOf, isArray, isEmpty, map, pull, reduce, size, without, findIndex, uniq } from 'lodash'
import type { Entities, EntityWithKey, Tags, TagType, TagWithKey } from '../../elements/tags/Tag'
import { NO_TAG_VALUE, TagTypes } from '../../elements/tags/Tag'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, Flex } from '@upgrowth/react-fulcrum'
import EntityCard from './EntityCard'
import type { FilterEntry, Filters } from './TagViewFilter'
import { FilterPredicates } from './TagViewFilter'
import { AddButton, ExtraActions, FilterDropdown, FilteredCount, GroupByDropdown, TagViewControls } from './TagViewControls'
import localstorage from 'local-storage'
import { findTagsPresentInItems } from './TagTable'
import { exportEntities } from '../../services/excel'
import { Badge } from '@upgrowth/react-fulcrum'
import type { Project } from '../../database'

type DragResult = {
  droppableId: string,
  index: number
}

class TagBoard extends React.Component<*> {
  props: {
    className?: string,
    tags: Tags,
    items: Entities,
    onChange?: (key: string, tagKey: string, value: any) => any,
    onAdd?: () => any,
    onClick?: (itemKey: string) => any,
    actions?: {},
    actions?: React.Node,
    project: Project
  }
  state: {
    tagKey: string,
    tagType: TagType,
    tags: Array<TagWithKey>,
    groupableTags: Array<TagWithKey>,
    columns: string[],
    groupedItems: {},
    filtered: Array<EntityWithKey>,
    filters: Filters,
    dragging: boolean,
    draggedItemKey?: string,
    visibleTags?: {},
    selectedItems: []
  } = {
    tagKey: '',
    tagType: undefined,
    tags: [],
    groupableTags: [],
    columns: [],
    groupedItems: {},
    filtered: [],
    filters: [],
    dragging: false,
    draggedItemKey: undefined,
    visibleTags: undefined,
    selectedItems: []
  }

  componentDidMount () {
    // clear selected tags when clicking elsewhere on the page
    document.addEventListener('click', this.clearSelectedItems)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.clearSelectedItems)
  }

  static getDerivedStateFromProps (nextProps: any, prevState: any) {
    const {tags, items, project} = nextProps
    const {pathname} = window.location
    const savedTagKey = localstorage.get(`board-group-${pathname}`)

    const tagsArray = map(tags, (value, key) => ({...value, key,}))
    const groupableTags = chain(tagsArray)
      .filter((value) => get(TagTypes, [value.type, 'discrete']))
      .sortBy('name')
      .value()

    const tag = find(groupableTags, ['key', prevState.tagKey]) || find(groupableTags, ['key', savedTagKey]) || first(groupableTags)
    const tagKey = tag ? tag.key : ''
    const tagType = tag && TagTypes[tag.type]
    const columns = tagType ? tagType.potentialValues(tag, project) : [NO_TAG_VALUE]
    const findColumnIndex = (val) => {
      let index = findIndex(columns, {value: val})
      if (index === -1) {
        index = indexOf(columns, NO_TAG_VALUE)
      }
      return index
    }

    const matchesFilter = (item) => every(prevState.filters, (filter: FilterEntry) => {
      const currentValue = get(item, filter.tagKey)
      const predicate = FilterPredicates[filter.operation]
      return predicate(filter.value, currentValue)
    })

    const filtered = chain(items)
      .map((value, key) => ({...value, key}))
      .filter(matchesFilter)
      .value()

    const groups = reduce(columns, (result, column) => ({...result, [column.value]: []}), {})
    // All of this is to support having multi-select cards show up in multiple columns,
    // otherwise we'd just use groupBy
    const groupedItems = reduce(filtered, (result, item) => {
      const {key} = item
      const value = item.tags && item.tags[tagKey]
      const valueArray = castArray(value)
      const columnIndexes = map(valueArray, findColumnIndex)
      each(columnIndexes, (index) => {
        const column = columns[index]
        result[column.value].push({...item, __column_item_key__column_item_key: `${key}___${column.value}`})
      })
      return result
    }, groups)
    // Initialise tag visibility to match any tags present across all items
    let {visibleTags} = prevState
    if (prevState.visibleTags === undefined && size(tags)) {
      visibleTags = findTagsPresentInItems(items, tags)
    }

    return {
      tagKey,
      tagType,
      tags: tagsArray,
      groupableTags,
      filtered,
      columns,
      groupedItems,
      visibleTags,
    }
  }

  onDragEnd = ({source, destination}: { source: DragResult, destination?: DragResult }) => {
    const {
      tagKey,
      tagType,
      groupedItems,
      selectedItems,
      filtered,
    } = this.state
    console.log('Drag end ', selectedItems)

    const {onChange} = this.props
    const {multiple} = tagType

    if (destination && onChange) {
      const {droppableId} = destination
      const value = droppableId === NO_TAG_VALUE.value ? null : droppableId
      const removeValue = source.droppableId === NO_TAG_VALUE.value ? null : source.droppableId
      const {key} = groupedItems[source.droppableId][source.index]
      const keys = isEmpty(selectedItems) ? [key] : selectedItems

      if (multiple) {
        chain(keys)
          .map((key) => find(filtered, ['key', key]))
          .compact()
          .each(({key, ...item}) => {
            const currentValue = castArray(get(item, ['tags', tagKey], []))
            // remove the old value, add the new one (in that order in case you drag into the same column)
            const newValue = uniq([...without(currentValue, removeValue), value])
            onChange(key, tagKey, newValue)
          })
          .value()
      } else {
        forEach(keys, (itemKey) => onChange(itemKey, tagKey, value))
      }
    }
    this.setState({dragging: false, draggedItemKey: undefined})
  }

  onDragStart = ({draggableId}) => {
    const state = {
      dragging: true,
      draggedItemKey: draggableId
    }

    const alreadySelected = includes(this.state.selectedItems, draggableId)
    console.log('Selected items ', draggableId, alreadySelected, this.state.selectedItems)
    if (!alreadySelected) {
      // if the item being dragged was not a part of the selected group, then clear the other items from the group.
      state.selectedItems = []
    }

    this.setState(state)
  }

  updateFilter = (filters: Filters) => this.setState({filters})
  updateVisibleTags = (visibleTags: {}) => this.setState({visibleTags})
  updateGroupBy = (tagKey: string) => {
    const {pathname} = window.location
    localstorage.set(`board-group-${pathname}`, tagKey)
    this.setState({tagKey})
  }

  wasGroupSelectionKeyUsed = (event: MouseEvent | KeyboardEvent) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  clearSelectedItems = (event) => {
    if (!event.defaultPrevented) {
      // only clear items if the click was not on a tag (i.e. in which case preventDefault() has been called)
      this.setState({selectedItems: []})
    }
  }
  toggleSelection = (draggableId: string, event: any) => {
    const {selectedItems} = this.state
    event.preventDefault() // let the clearSelection event handler know not to clear the group selection

    const alreadySelected = includes(selectedItems, draggableId)

    if (this.wasGroupSelectionKeyUsed(event)) {
      if (alreadySelected) {
        // remove the item from the group if its already selected
        pull(selectedItems, draggableId)
      }
      else {
        // add the item to the group if its not already selected
        selectedItems.push(draggableId)
      }

      this.setState({selectedItems})
    }
    else if (!alreadySelected) {
      // if the group selection key is not used, just clear the entire group and select this one item
      this.setState({selectedItems: [draggableId]})
    }
    console.log('Selected ', draggableId, selectedItems)

    // in the event that the item is already selected and the group selection key was not used, then nothing happens (as per MacOS Finder).
  }

  render () {
    const {
      tagKey,
      tags,
      groupableTags,
      columns,
      groupedItems,
      filtered,
      filters,
      dragging,
      draggedItemKey,
      visibleTags,
      selectedItems
    } = this.state
    const {className = '', onAdd, actions, items, onClick, rowActions, name} = this.props
    return (
      <div className={`TagBoard ${className}`}>
        <TagViewControls>
          {actions}
          <GroupByDropdown value={tagKey}
                           tags={groupableTags}
                           onChange={this.updateGroupBy} />
          <Flex />
          <FilteredCount count={size(filtered)} total={size(items)} />
          <FilterDropdown filters={filters} tags={tags} onChange={this.updateFilter} />
          {/*<ColumnsDropdown value={visibleTags} tags={tags} onChange={this.updateVisibleTags}/>*/}
          <ExtraActions>
            <a onClick={() => exportEntities(filtered, this.props.tags, visibleTags, name, 'xlsx')}>Export to Excel</a>
            <a onClick={() => exportEntities(filtered, this.props.tags, visibleTags, name, 'csv')}>Export to CSV</a>
          </ExtraActions>
          {onAdd && <AddButton onClick={onAdd} />}
        </TagViewControls>
        <div className={`board ${dragging ? 'dragging' : ''}`}>
          <header className='board-headers'>
            {map(columns, (column, index) => (
                <div className="column-header" key={index}>
                  <label>
                    <span>{column.label}</span>
                    <span>{ size(groupedItems[column.value]) }</span>
                  </label>
                </div>
            ))}
          </header>
          <div className='board-columns'>
          <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
            {map(columns, (column, index) => (
              <div className="column" key={index}>
                <Droppable droppableId={column.value} key={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                         className={`column-content ${this.state.dragging ? 'dragging' : ''}`}>
                      {map(groupedItems[column.value], (item, index) => (
                        <Draggable
                          key={item.__column_item_key || index}
                          draggableId={item.key}
                          index={index}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef}
                                 {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                                className='card-wrapper'>
                              { draggedItemKey === item.key && selectedItems.length > 1 && <Badge variant="primary">{selectedItems.length}</Badge>}
                              <EntityCard
                                itemKey={item.key}
                                name={item && item.name}
                                tags={item && item.tags}
                                availableTags={tags}
                                visibleTags={undefined}
                                openContextMenu={onClick}
                                actions={rowActions}
                                onClick={(e) => onClick(item.key, e)}
                                onSelect={(e) => this.toggleSelection(item.key, e)}
                                className={includes(selectedItems, item.key) ? 'selected' : ''}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </DragDropContext>
          </div>
        </div>
      </div>
    )
  }
}

export default TagBoard
