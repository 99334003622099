// @flow

import './SimpleWizard.scss'
import * as React from 'react'
import type { FormType } from '@upgrowth/react-fulcrum'
import { Button, Flex, Form, Row } from '@upgrowth/react-fulcrum'

export type WizardPage = {
    title?: string,
    description?: string,
    actionLabel?: string,
    fields: FormType
}

class SimpleWizard extends React.Component {
    props: {
        className?: string,
        pages: Array<WizardPage>,
        onChange: (value: {}) => any,
        onNext: (value: {}, page: number) => any,
        onComplete: (value: {}) => any
    }
    state: {
        page: number
    } = {
        page: 0
    }

    handleChange = (value: {}) => {
        const {onChange, onComplete} = this.props
        onChange(value)
    }

    handleSubmit = (value: {}) => {
        const {onChange, onNext, onComplete, pages} = this.props
        let {page} = this.state
        onChange && onChange(value)
        page = page + 1
        const last = page >= pages.length
        if (last) {
            onComplete && onComplete(value)
            page = 0;
        }else {
            onNext && onNext(value, page - 1)
        }
        this.setState({page})
    }

    render() {
        const {className = "", pages, value, ...props} = this.props
        const {page} = this.state
        const {fields, title, description, actionLabel = "Next", ...passProps} = pages[page]

        const titleContent = typeof title === "string" ? <h3>{title}</h3> : title

        return (
            <div className={`SimpleWizard ${className}`}>
                {title && titleContent}
                {description && <div className="description">{description}</div>}
                <Form value={value}
                      onChange={this.handleChange}
                      onSubmit={this.handleSubmit}
                      fields={fields}
                      {...passProps}
                >
                    {actionLabel && <Row>
                        <Flex/>
                        <Button type="submit">{actionLabel}</Button>
                    </Row>
                    }
                </Form>
            </div>
        )
    }
}

export default SimpleWizard