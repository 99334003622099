// @flow

import "./Loading.scss"
import React from 'react'
import Icon from '../elements/icons/Icon'

const Loading = ({className = ""}) => {
    return (
        <div className={`Loading ${className}`} style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <Icon icon="loading"/>
        </div>
    );
};

export default Loading;