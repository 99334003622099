// @flow
import './EntityCard.scss'
import * as React from 'react'
import { chain, filter, get, keyBy, map, mapValues, pickBy, size } from 'lodash'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { Column, Flex, Label, Row } from '@upgrowth/react-fulcrum'
import Tags from '../../compositions/tags/Tags'
import type { Tag } from '../../elements/tags/Tag'
import { TagViewer } from '../../elements/tags/Tag'
import { ActionsDropdown } from './TagViewControls'
import classnames from 'classnames'

type Props = {
  itemKey: string,
  name: string,
  tags: {},
  availableTags: Tag[],
  className: string,
  onSelect?: () => any,
  onClick?: () => any,
}

export default ({itemKey, name, tags, onClick = () => undefined, onSelect, visibleTags = mapValues(tags, () => true), availableTags, openContextMenu, actions, className, ...props}: Props) => {

  const tagMeta = keyBy(availableTags, 'key')
  const tagsToShow = chain(visibleTags).pickBy(show => show).mapValues((show, key) => tags[key]).value()

  return (
    <div className={classnames('EntityCard', className)} onClick={onSelect} {...props}>
      <Row className="lead-container">
        <a className="tag-name" onClick={onClick}>{name}</a>
        <Flex />
        {actions && <ActionsDropdown actions={actions} itemId={itemKey} />}
      </Row>
      <Tags
        className="tag-list"
        tags={tagsToShow}
        renderTag={(value, attrKey) =>
          <Column key={attrKey} className={`Tag`}>
            <Label className="variant-primary">{tagMeta[attrKey] && tagMeta[attrKey].name}</Label>
            <TagViewer
              className="tag-card-value"
              tag={tagMeta[attrKey]}
              value={value}
            />
          </Column>
        }
      />
    </div>
  )
};

