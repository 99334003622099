// @flow

import * as React from 'react'
import { imageUrl } from './images'
import { Button } from '@upgrowth/react-fulcrum'
import { Link } from '@upgrowth/reactkit'
import Markdown from 'react-markdown'
import { isPlainObject, zip } from 'lodash'
import './ContentBlock.scss'

const Cards = ({value}) => <div className='Cards'>
  {value.map((entry, index) => {
    const {image, body} = entry.value
    return (
      <section className='Cards-section' key={index}>
        <img alt='card' src={imageUrl(image)} />
        <Markdown source={body} />
      </section>
    )
  })}
</div>

const Image = ({value: {src} = {}}) => <img alt="the" className='Image' src={imageUrl(src)} />
const LinkButton = ({value: {to, label, variant = 'secondary'} = {}}) => <Button className='LinkButton' variant={variant} as={Link} to={to}>{label}</Button>

const Block = ({value: {content = ''} = {}}) => <Markdown className='Block' source={content} />

const pricingContent = [
  [
    'Personal',
    '*1* project',
    '*5* recordings',
    'Up to *2* collaborators',
    'free'
  ], [
    'Team',
    'Unlimited projects',
    'Unlimited recordings',
    'Unlimited collaborators',
    '$25'
  ], [
    'Enterprise',
    'Unlimited projects',
    'Unlimited recordings',
    'Unlimited collaborators',
    'call us'
  ],
]
const PricingChart = ({}) => {
  const rows = zip(...pricingContent)

  return (
    <div className='PricingChart'>
      {rows.map((row, rowId) => (
        <div className='row' key={rowId}>
          {row.map((content, colId) => (
            <Markdown source={content} key={colId} className='cell' escapeHtml={false} />
          ))}
        </div>
      ))}
    </div>
  )
}
const contentBlocks = {
  text: Block,
  image: Image,
  link: LinkButton,
  cards: Cards,
  pricing: PricingChart
}

const ContentBlock = ({type, value}: { type: string, value: * }) => {
  const As = contentBlocks[type] || "div"
  if(As === "div" ){ console.log("Couldnt find ", type)}
  return <As value={value} />
}

export default ContentBlock
