//@flow

import *  as  React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { Flex, Modal, NavItem, NavMenu } from '@upgrowth/react-fulcrum'
import { map } from 'lodash'
import CreateSegment, { CreateSegmentModal } from '../../compositions/segments/CreateSegment'
import CreateResearchItem from '../../compositions/research/CreateResearchItem'
import CreateInsight from '../../compositions/insights/CreateInsight'
import { FiPlus } from 'react-icons/fi'
import { withProject } from '../../services/withProject'

const AddSomething = ({onClick}:{onClick: ()=>any}) =>  (
    <div className="inline-action" onClick={onClick}><FiPlus/></div>
)


class ProjectNavItems extends React.Component {
    state: {
        modal?: string
    } = {
        modal: undefined
    }

    showModal = (modal) => this.setState({modal})
    closeModal = () => this.setState({modal: undefined})

    render() {
        const {projectId, project = {}} = this.props
        const {modal} = this.state
        return (
            <React.Fragment>
                <NavItem as={NavLink} projectId={projectId} name="research" exact>
                    Research
                    <AddSomething onClick={() => this.showModal("research")}/>
                </NavItem>
                <NavMenu>
                    <NavItem as={NavLink} projectId={projectId} name="research" exact>All</NavItem>
                    <NavItem as={NavLink} projectId={projectId} name="researchMoments" exact>Moments</NavItem>
                </NavMenu>
                <NavItem as={NavLink} projectId={projectId} name="segments" exact>
                    Segments
                    <AddSomething onClick={() => this.showModal("segment")}/>
                </NavItem>
                <NavMenu>
                    {map(project.segments, (segment, id) =>
                        <NavItem key={id} as={NavLink} projectId={projectId} segmentId={id}
                                 name="segment">{segment.name}</NavItem>
                    )}
                </NavMenu>

                <NavItem as={NavLink} projectId={projectId} name="insights" exact>
                    Insights
                    <AddSomething onClick={() => this.showModal("insight")}/>
                </NavItem>
                <NavMenu>
                    <NavItem as={NavLink} projectId={projectId} name="insights">All</NavItem>
                </NavMenu>
                <NavItem as={NavLink} projectId={projectId} name="tags">Tags</NavItem>
                <Flex/>

                <NavItem as={NavLink} projectId={projectId} name="team">Team</NavItem>

                <Modal isOpen={modal !== undefined} onRequestClose={this.closeModal}>
                    { modal === "segment" && <CreateSegment projectId={projectId} onComplete={this.closeModal}/>}
                    { modal === "research" && <CreateResearchItem projectId={projectId} onComplete={this.closeModal} tags={project.tags}/>}
                    { modal === "insight" && <CreateInsight projectId={projectId} onComplete={this.closeModal} tags={project.tags}/>}
                </Modal>
            </React.Fragment>
        )
    }
}

export default withParams(withProject(ProjectNavItems))

