// @flow
import React from 'react'
import { firebind, firekit } from '@upgrowth/firekit'
import { encode } from 'firebase-key'
import { isEmpty, map, size } from 'lodash'
import { acceptInvites, rejectInvites } from '../services/projects'
import Modal from '../layouts/Modal'
import { Button } from '@upgrowth/react-fulcrum'

const AutoSubscriber = ({invites}) => {
  if (isEmpty(invites)) { return null }
  return (
    <Modal isOpen={true}
           title="Join projects"
           actions={
             <>
               <Button onClick={() => acceptInvites(invites)}>Join All</Button>
               <Button onClick={() => rejectInvites(invites)}>Reject All</Button>
             </>
           }>
      <p>You have been invited to the project{size(invites) > 1 ? 's' : ''}:</p>
      <ul>
        {map(invites, (projectName, id) => <li key={id}>{projectName}</li>)}
      </ul>
    </Modal>
  )
}

const withUser = (Component) => class WithUser extends React.PureComponent {
  state = {}

  componentDidMount () {
    this.unsub = firekit().auth.onAuthStateChanged(user => this.setState({user}))
  }

  componentWillUnmount () {
    this.unsub && this.unsub()
    delete this.unsub
  }

  render () {
    return <Component {...this.props} user={this.state.user} />
  }
}

export default withUser(firebind(AutoSubscriber, {
  invites: ({user}) => user && user.email && `invites/${encode(user.email)}`
}))