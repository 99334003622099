// @flow

import * as React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { map } from 'lodash'
import type { Project } from '../../database'
import SiteSection from '../../compositions/SiteSection'
import Page from '../../compositions/Page'
import CardGrid from '../../compositions/CardGrid'
import Card from '../../components/Card'
import { FiCornerDownRight } from 'react-icons/fi'
import { Button } from '@upgrowth/react-fulcrum'
import { withProject } from '../../services/withProject'
import { FiUsers, FiTag, FiPieChart, FiBookOpen, FiStar } from "react-icons/fi";
import Icon from '../../elements/icons/Icon'

const links = [
  {label: 'Team', link: 'team', icon: 'team'},
  {label: 'Tags', link: 'tags', icon: 'tags'},
  {label: 'Research', link: 'research', icon: 'research'},
  {label: 'Segments', link: 'segments', icon: 'segments'},
  {label: 'Insights', link: 'insights', icon: 'insights'},
]

const ViewProject = withParams(withProject(({
                                           project = {
                                             details: {},
                                             tagGroups: {}
                                           }, projectId, history
                                         }: { project: Project, projectId: string }) => (
  <SiteSection className='Project'>
    <Page size="normal">
      <h1>{project.details.name}</h1>
      <CardGrid>
        {map(links, ({label, link, icon}) => (
          <Card key={link}>
            <h3>{label}</h3>
            <div className="card-icon">
              <NavLink projectId={projectId} name={link}>
                <Icon icon={icon}/>
              </NavLink>
            </div>
            <Button as={NavLink} projectId={projectId} name={link}>
              View {label}
            </Button>
          </Card>
        ))}
      </CardGrid>
    </Page>
  </SiteSection>
)))

export default ViewProject