// @flow

import React from 'react'
import './SingleColumn.scss'
import Column from './Column'

const SingleColumn = ({children, actions, className = '', as = 'div', ...props}: { children: React.Node, actions: React.Node, className?: string }) => (
  <Column as={as} className={`SingleColumn ${className}`} actions={actions} {...props}>{children}</Column>
)

export default SingleColumn