import type { UserRole } from './database'
import {reduce} from "lodash"
const ROLE_ADMIN = "admin"
const ROLE_EDITOR = "editor"
const ROLE_VIEWER = "viewer"

const ROLES = {
  admin: ROLE_ADMIN,
  editor: ROLE_EDITOR,
  viewer: ROLE_VIEWER,
}

const hasRole = (userRole: UserRole, ...oneOf) =>  userRole && oneOf.indexOf(userRole) >= 0
const hasEditorRole = (userRole: UserRole) =>  hasRole(userRole, ROLE_ADMIN, ROLE_EDITOR)

export {
  hasRole,
  hasEditorRole,
  ROLES,
  ROLE_ADMIN,
  ROLE_EDITOR,
  ROLE_VIEWER
}
