// @flow

import "./Icon.scss"
import * as React from 'react'
import { FiUsers, FiTag, FiPieChart, FiBookOpen, FiStar, FiPlus, FiMoreVertical, FiFilter, FiFilm, FiX, FiCrosshair } from 'react-icons/fi'

const icons = {
  'team': FiUsers,
  'tags': FiTag,
  'research': FiBookOpen,
  'insights': FiStar,
  'segments': FiPieChart,
  add: FiPlus,
  more: FiMoreVertical,
  filter: FiFilter,
  project: FiFilm,
  close: FiX,
  loading: FiCrosshair
}

export type IconName =
  'research' |
  'team' |
  'tags' |
  'insights' |
  'segments' |
  "add" |
  "more" |
  "filter" |
  "loading"

const Icon = ({icon, className = ''}: { icon: IconName, className: string }) => {
  const Icon = icons[icon] || <div/>
  return (<Icon className={`Icon ${className}`} />)
}

export default Icon