// @flow

import './Profile.scss'
import * as React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { firekit } from '@upgrowth/firekit'
import { chain, cloneDeep, first, get, map, mapValues, pickBy, set, trim } from 'lodash'
import Loading from '../../components/Loading'
import Tags from '../../compositions/tags/Tags'
import { Switch } from '@upgrowth/react-fulcrum'
import Page from '../../compositions/Page'
import RenderEntity from '../../compositions/entity/RenderEntity'
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton'
import type { FirebaseError, UploadTask } from 'firebase'
import { TagEditor, TagViewer } from '../../elements/tags/Tag'
import { withProject } from '../../services/withProject'
import { hasEditorRole } from '../../roles'

class Profile extends React.Component<*> {
  props: {
    segment: {},
    projectId: string,
    segmentId: string,
    profileId: string,
    profile: {},
    tags: Tags
  }
  state: {
    working: boolean,
    current: string,
    isEditing: boolean
  } = {
    isEditing: false,
    working: false,
    current: '',
  }

  toggleEditing = () => {
    const {isEditing} = this.state
    this.setState({isEditing: !isEditing})
  }

  updateTagOnProfile = async (tagId, value) => {
    const {projectId, segmentId, profileId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    const path = `projects/${projectId}/segments/${segmentId}/profiles/${profileId}/tags/${tagId}`
    await database.ref(path).set(value)
    this.setState({working: false})
  }

  updateProfile = async (formPayload) => {

    console.log('FORM PAYLOAD: ', formPayload)

    const {projectId, segmentId, profileId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    const path = `projects/${projectId}/segments/${segmentId}/profiles/${profileId}`
    await database.ref(path).update(formPayload)
    this.setState({working: false})
  }

  handleStart = (file: File) => {
    console.log('START')
  }

  handleProgress = (progress: number) => {
    this.setState({progress})
  }

  handleError = (error: FirebaseError) => {
    console.error(error)
    this.setState({error, uploaded: false})
  }

  handleComplete = async (filename: string, task: UploadTask) => {

    console.log('DONE')

    const {database} = firekit()
    const {projectId, segmentId, profileId} = this.props

    const storageRef = this.profileStorageRef().child(filename)
    const mediaUrl = await storageRef.getDownloadURL()
    const {size} = await storageRef.getMetadata()

    const fileData = {
      filename, size, storageUrl: storageRef.toString(), mediaUrl
    }

    console.info(`Successfully uploaded ${filename}`)

    this.setState({working: true})
    const path = `projects/${projectId}/segments/${segmentId}/profiles/${profileId}/image`
    await database.ref(path).update(fileData)

    this.setState({working: false, uploaded: true, progress: 0})
  }

  profileStorageRef = () => {
    const {app} = firekit()
    const {projectId, segmentId, profileId} = this.props
    const path = `projects/${projectId}/segments/${segmentId}/profiles/${profileId}`
    return app.storage().ref(path)
  }

  render () {
    const {segmentId, project: {tags = {}, segments}, profileId, loading, userRole} = this.props
    const segment = segments[segmentId]
    let {isEditing} = this.state

    if (loading) {
      return <Loading />
    }
    const editable = hasEditorRole(userRole)
    isEditing = isEditing && editable

    const profile = get(segment, ['profiles', profileId]) || {}
    const segmentTags = get(segment, ['tags'])

    const profileTags = mapValues(segmentTags, tag => get(profile, ['tags', tag], ''))

    console.log('PROFILE TAGS: ', profileTags)

    const defaultedProfile = {
      tags: profileTags,
      ...profile
    }

    const {error, progress, form, uploaded, working} = this.state

    return (
      <Page className="Profile" size="normal">
        {editable && <Switch className="edit-mode-toggle" value={isEditing} onChange={this.toggleEditing}
                             label="Edit"
                             variant="light" />
        }

        {
          isEditing ?
            <CustomUploadButton accept="png, jpg, jpeg"
                                randomizeFilename
                                className="upload-button"
                                onUploadStart={this.handleStart}
                                onUploadSuccess={this.handleComplete}
                                onProgress={this.handleProgress}
                                onUploadError={this.handleError}
                                storageRef={this.profileStorageRef()}>
              <div className='progress' style={{width: `${progress}%`}} />
              {
                progress > 0
                  ? <div className='name'>Uploading — {progress}%</div>
                  : <div className='name'>{profile.image ? 'Change Image' : 'Click to upload'}</div>
              }

            </CustomUploadButton>
            :
            <img className="profile-image" src={profile.image && profile.image.mediaUrl} />

        }

        <RenderEntity
          entity={defaultedProfile}
          tags={tags}
          onChange={this.updateProfile}
          isEditing={editable && isEditing}
        />
      </Page>
    )
  }
}

export default withParams(withProject(Profile))
