//@flow
import './RenderEntity.scss'
import * as React from 'react'
import type { Entity, Tag, Tags } from '../../elements/tags/Tag'
import { chain, chunk, includes, map, omitBy, size, keys, set, get, isEmpty, cloneDeep } from 'lodash'
import { TagEditor, TagViewer } from '../../elements/tags/Tag'
import AddTag from '../../components/tags/AddTag'
import { Form, Row, FormRow } from '@upgrowth/react-fulcrum'
import {FiX} from "react-icons/fi"

const nameRender = ((value) => <h1>{value}</h1>)
const descriptionRender = ((value) => <p>{value || 'None'}</p>)

const FieldRender = {
  'name': nameRender,
  'description': descriptionRender
}

const FieldMeta = {
  'name': {
    label: 'Name',
    name: 'name',
    type: 'text',
    validation: 'required',
    placeholder: 'Name',
    autoComplete: 'off',
  },
  'description': {
    name: 'description',
    type: 'textarea',
    label: 'Description',
    placeholder: 'Description',
  }
}

const smallTags = ['select', 'percent', 'scale']

class RenderEntity extends React.Component<*> {
  props: {
    entity: Entity,
    className?: string,
    tags: Tags,
    onChange?: (formData: Tag) => any,
    children: React.Node,
    isEditing: boolean,
    canAddTags: boolean,
    fieldOptions: {
      name?: boolean,
      image?: boolean,
      description?: boolean,
    }
  }

  state: {}

  handleAddTag = (tagKey, value) => {
    const {onChange, entity = {}} = this.props

    const update = set(cloneDeep(entity), ['tags', tagKey], value)
    onChange(update)
  }

  getFormLayout = () => {
    const {fieldOptions = {name: true, description: true}} = this.props
    return chunk(keys(omitBy(fieldOptions, false)), 1)
  }

  getTagLayout = () => {

    const {entity = {}, tags = {}} = this.props

    const {small = [], normal = []} = chain(entity.tags)
      .map((value, key) => ({...tags[key], key}))
      .groupBy(({type = 'text'}) => includes(smallTags, type) ? 'small' : 'normal')
      .mapValues((items) => map(items, ({key}) => `tags.${key}`))
      .mapValues((items, size) => chunk(items, size === 'small' ? 2 : 1))
      .value()

    return [...small, ...normal]

  }

  getFormFields = () => {

    const {isEditing, fieldOptions = {name: true, description: true}} = this.props

    const fieldsToRender = keys(omitBy(fieldOptions, false))

    return map(fieldsToRender, fieldKey => ({
      ...FieldMeta[fieldKey],
      render: !isEditing && FieldRender[fieldKey]
    }))
  }

  getTagFields = () => {

    const {entity = {tags: {}}, tags, isEditing = false, canAddTags = false} = this.props

    const renderTag = isEditing ? TagEditor : TagViewer

    return !isEmpty(tags) ? map(entity.tags, (value = "", key) => {
      return {
      name: `tags.${key}`,
      label: get(tags, [key, 'name']),
      value,
      render: (value = "", onChange = (value: any) => null) =>
        get(tags, [key]) ? // if a tag definition has been removed, just hide the field
        <Row className="tag-row">
          {renderTag({tag: tags[key], value, onChange})}
          {canAddTags && isEditing && <a className="remove-tag" href="javascript:" onClick={() => onChange(null)}><FiX/></a>}
        </Row> : <div/>
    }}) : []
  }

  getFields = () => [...this.getFormFields(), ...this.getTagFields()]
  getLayout = () => [...this.getFormLayout(), ...this.getTagLayout()]

  render () {

    const {
      className = "",
      isEditing = false,
      canAddTags = false,
      entity = {tags: {}},
      tags = {},
      children,
      onChange,
      fieldOptions,
      ...props
    } = this.props

    const layout = this.getLayout()
    const fields = this.getFields()

    const tagsToAdd = omitBy(tags, (_, key) => get(entity, ['tags', key]) !== undefined)

    return (
      <Form
        className={`RenderEntity ${className}`}
        onChange={onChange}
        value={entity}
        layout={layout}
        fields={fields}
        {...props}
      >

        {
          canAddTags && isEditing &&
          <FormRow>
            <AddTag tags={tagsToAdd} onSelect={(tag) => this.handleAddTag(tag, '')} />
          </FormRow>
        }
        {children}
      </Form>
    )
  }

}

export default RenderEntity
