// @flow

import './Segments.scss'
import * as React from 'react'
import { NavLink, Route, Switch, withParams } from '@upgrowth/reactkit'
import { firekit } from '@upgrowth/firekit'
import { isEmpty, map, trim } from 'lodash'
import { Button, Modal } from '@upgrowth/react-fulcrum'
import Segment from './Segment'
import Profile from './Profile'
import SiteSection from '../../compositions/SiteSection'
import SegmentsOverview from './SegmentsOverview'
import CreateSegment from '../../compositions/segments/CreateSegment'
import { withProject } from '../../services/withProject'

const SegmentInstructions = ({onClick}) => (
  <div className="introduction">
    <h1>Segment</h1>
    <div>
      <p>
        Welcome to the segment section of Forest.
      </p>
      <p>
        This is where you and your team can create groups of your users and customers to help inform your
        business process.
      </p>
      <p>
        To get started, create your first segment.
      </p>
    </div>
    <Button onClick={onClick}>Create New Segment</Button>
  </div>
)

class Segments extends React.Component<*> {
  props: {
    projectId: string,
    segments: {}
  }
  state: {
    working: boolean,
    create: boolean,
  } = {
    working: false,
    create: false
  }

  createSegment = async (form) => {
    const {projectId} = this.props
    const {database} = firekit()
    const path = `projects/${projectId}/segments`

    this.setState({working: true})
    await database.ref(path).push({...form, type: 'segment'})
    this.setState({form: {}, working: false})
  }

  render () {

    const {project: {segments}, projectId, userRole} = this.props
    const {working, create} = this.state

    const content = isEmpty(segments) ?
      <SegmentInstructions onClick={() => this.setState({create: true})} />
      : (
        <Switch>
          <Route name='segments' exact
                 render={() => <SegmentsOverview segments={segments} projectId={projectId} userRole={userRole}/>} />
          <Route name='profile' component={Profile} />
          <Route name='segment' component={Segment} />
        </Switch>
      )

    return (
      <SiteSection className="Segments">

        {content}

        <Modal isOpen={create} onRequestClose={() => this.setState({create: false})}>
          <CreateSegment projectId={projectId} onComplete={() => this.setState({create: false})} />
        </Modal>
      </SiteSection>
    )
  }
}

export default withParams(withProject(Segments))