// @flow

import * as React from 'react'
import { get, map } from 'lodash'
import ContentBlock from './ContentBlock'
import * as content from './content'
import { Button, Form } from '@upgrowth/react-fulcrum'
import { backgroundImage } from './images'
import AboutNav from './AboutNav'
import { ScrollToTopOnNav, withParams, Redirect } from '@upgrowth/reactkit'
import './AboutPage.scss'
import Markdown from 'react-markdown'
import Flex from '@upgrowth/react-fulcrum/lib/compositions/layout/Flex'
import arrowHead from './arrow-head.svg'
import Loading from '../../components/Loading'
import { Firebind } from '@upgrowth/firekit'

const Footer = ({headline, body, button, placeholder, label}) => (
    <footer className='Footer'>
      <Markdown source={body} />
      <Form fields={[{name: 'email', type: 'email', placeholder, label}]}>
        <Button type='submit'>{button}</Button>
      </Form>
    </footer>
)

const Header = ({headline, headlineImage, pageId}) => (
  <header className='AboutPage-header' style={backgroundImage(headlineImage)}>
    <Flex />
    <Markdown source={headline} />
    <div className='arrow'>
      <div className='arrow-tail' />
      <div className='arrow-head'>
        <img src={arrowHead} />
      </div>
    </div>
  </header>
)

const LegalFooter = ({}) => (
  <div className='legal-footer'>
    <div>© 2018</div>
    <div>Made with ♥️ in Sydney, Australia by <a href='https://www.upgrowth.com.au'>Upgrowth</a></div>
    <div><a href='https://www.upgrowth.com.au/privacy-policy'>Privacy Policy</a></div>
  </div>
)
const showFooter = false

const AboutPage = ({headline, headlineImage, body, pageId}) => (
  <div className='AboutPage'>
    <ScrollToTopOnNav className={pageId} />
    <AboutNav pageId={pageId} />
    <Firebind bind={{content: `/firelight/content/current/content/${pageId}`}}
              whileLoading={<Loading />}>
      {({content}) => {
        const {headline, headlineImage, body} = get(content, 'value')
        if(content === undefined){
          return <Redirect to="/"/>
        }
        return (
          <React.Fragment>
            <Header headline={headline} headlineImage={headlineImage} pageId={pageId} />
            <div className='AboutPage-body'>
              {map(body, ({type, value}, key) => <ContentBlock type={type} value={value} key={key} />)}
            </div>
            {showFooter && <Footer {...content.footer} />}
          </React.Fragment>
        )
      }}
    </Firebind>
    <Flex/>
    <LegalFooter />
  </div>
)

const BoundAboutPage = withParams(({pageId}) => <AboutPage pageId={pageId || "home"} />)

export default BoundAboutPage