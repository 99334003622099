// @flow

import * as React from 'react';
import {Button, Flex, FormRow} from "@upgrowth/react-fulcrum";
import {firebind, firekit} from "@upgrowth/firekit";
import {chain, cloneDeep, find, get, last, map, set, without} from "lodash"
import Link from "../../elements/insights/Link";
import TagTable from "../../components/tags/TagTable";
import RenderEntity from "../entity/RenderEntity";
import { withProject } from '../../services/withProject'

class CreateInsight extends React.Component {
  props: {
    projectId: string,
    onComplete: (id: string) => any,
    include: {},
    project: {
      moments: {},
      research: {},
      insights: {},
      tags: {},
    }
  }
  state: {
    form: {
      name: string,
      description: string,
      tags: {}
    },
    working: boolean,
    include: Link
  } = {
    form: {
      name: '',
      description: '',
      tags: {}
    },
    working: false,
    include: {}
  }
  createInsight = async (form: {}) => {
    const {projectId, onComplete} = this.props
    const {include} = this.state
    const {database} = firekit()
    const path = `projects/${projectId}/insights`

    this.setState({working: true})
    const result = await database.ref(path).push({...form, type: 'insight', links: include})
    this.setState({working: false})
    onComplete && onComplete(result.ref)
  }
  addInclude = (key) => {
    const {project: {moments, insights, research}, projectId} = this.props
    const items = {...moments, ...insights, ...research}
    const item = items[key]
    if (item) {
      const {type} = item
      const link = {type, key}
      const {include = []} = this.state
      this.setState({include: [...include, link]})
    }
  }
  removeInclude = (remove) => {
    const {include} = this.state
    this.setState({include: without(include, remove)})
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const {include} = nextProps
    if (include !== undefined && prevState.include === undefined) {
      return {include}
    }
    return null
  }

  render () {
    const {project: {moments, insights, research, tags}, projectId} = this.props
    const {working, include, form} = this.state
    const items = {...moments, ...insights, ...research}
    return (
      <div className={`CreateInsight`}>
        <h1>Create new Insight</h1>
        <RenderEntity onSubmit={this.createInsight}
                      isEditing
                        onChange={(form) => this.setState({form})}
                        tags={tags}
                        disabled={working}
                        entity={form}
                        canAddTags
        >
          <h3>Linked data</h3>
          <div className="links">
            {map(include, (link, key) =>
              <Link
                projectId={projectId}
                key={key}
                link={link}
                clickable={false}
                onRemove={this.removeInclude}
              />
            )}
          </div>
          <TagTable
            items={items}
            types={true}
            content={true}
            tags={tags}
            columns={{name: true, description: true, type: true}}
            onClick={this.addInclude}
          />
          <FormRow>
            <Flex />
            <Button type="submit">Create Insight</Button>
          </FormRow>
        </RenderEntity>
      </div>
    )
  }
}

export default withProject(CreateInsight)
