//@flow

import './AddTag.scss'
import React from 'react'
import { chain, get, map } from 'lodash'
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown'
import { FiPlus } from 'react-icons/fi'

class AddTag extends React.Component {
    handleClose = () => {
        const {ref} = this.refs
        if (ref) {
            ref.hide()
        }
        return true;
    }

    handleSelect = (key: string)=>{
      const {onSelect} = this.props
      this.handleClose()
      console.log("On select ", key , onSelect )
      onSelect && onSelect(key)
    }
    render() {
        const {className = "", tags, onSelect} = this.props
        return tags ? (
            <Dropdown ref="ref" className={`AddTag ${className}`}>
                <DropdownTrigger>
                    <FiPlus className="dropdown__indicator" size={16} /> Tag
                </DropdownTrigger>

                <DropdownContent>
                    {map(tags, ({name}, key) => <a key={key}
                                                         href="javascript:"
                                                         onClick={() => this.handleSelect(key)}>{name}</a>)}
                </DropdownContent>
            </Dropdown>
        ) : null
    }
}

export default AddTag
