// @flow

import './PageHelp.scss'
import React from 'react'
import { firebind } from '@upgrowth/firekit'
import pathToRegexp from 'path-to-regexp'
import { chain } from 'lodash'
import Markdown from 'react-markdown'
import { withRouter } from 'react-router'
import Icon from '../../elements/icons/Icon'

type MarkdownType = string
type Help = {
  path: string,
  title: string,
  content: MarkdownType,
}
const countRegex = /\//g

const findBestMatchingPath = (help, location) => {
  return chain(help)
    .map('value')
    .sortBy(({path}) => {
      // sort by the number of slashes/segments, followed by the overall string length
      const segments = path.match(countRegex || []).length
      return [segments, path.length]
    })
    .reverse()
    .find(({path}, key) => {
      const regexp = pathToRegexp(path)
      return location.match(regexp)
    })
    .value()
}

class PageHelp extends React.Component {
  state: {
    location: string,
    show: boolean,
  } = {
    location: '',
    show: false
  }

  componentDidMount () {
    const {location} = this.props
    this.setState({location: location.pathname})
  }

  componentDidUpdate (prevProps, prevState) {
    const {location} = this.props
    if (location.pathname !== prevState.location) {
      this.setState({location: location.pathname})
    }
  }

  show = () => {
    this.setState({show: true})
  }
  hide = () => {
    this.setState({show: false})
  }

  render () {
    const {className = '', help, ...props} = this.props
    const {location, show} = this.state

    const helpEntry = findBestMatchingPath(help, location)
    return (
        <div className='PageHelp'>
        <a className="nav-action" href='javascript:' disabled={!helpEntry} onClick={this.show}>Help</a>
        {helpEntry && <div className={`content ${className} ${show ? 'visible' : 'hidden'}`}>
          <div className='help-content'>
            <a className='close-action' href='javascript:' onClick={this.hide}><Icon icon="close" /><div className='label'>Close</div></a>

            <h1>{helpEntry.title}</h1>
            <Markdown source={helpEntry.content} />
          </div>
        </div>
        }
        </div>
    )
  }
}

export default withRouter(firebind(PageHelp, {
  help: `/firelight/content/current/help`
}))