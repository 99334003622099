// @flow
import * as React from 'react'
import { withParams } from '@upgrowth/reactkit'
import { map, trim } from 'lodash'
import { Input } from '@upgrowth/react-fulcrum'
import type { Tag } from './Tag'

export type NumberTag = {
    options: string[]
} & Tag

class NumberTagEditor extends React.Component<*> {
    prop: {
        tagKey: string,
        tag: Tag,
        value: any,
        onChange: (value: any) => any,
    };

    render() {
        const {value, onChange, tag = {}} = this.props

        return <Input
            type="number"
            placeholder={tag.name}
            value={value}
            onChange={onChange}
        />
    }
}

const NumberTagViewer = ({className, tag, value}: {className: string, tag: NumberTag, value: string}) => (
    <h5 className={`NumberTagViewer ${className}`}>{value}</h5>
)

export {NumberTagEditor, NumberTagViewer}
