// @flow

import * as React from 'react'
import { Firebind, withUserId } from '@upgrowth/firekit'
import Loading from '../components/Loading'

const {Provider, Consumer} = React.createContext({project: {}, tags: {}, moments: {}, research: {}, segments: {}, userRole: undefined, noProject: true})

export const ProjectProvider = withUserId(({userId, projectId, children}) => <Firebind projectId={projectId}
                                                                                       userId={userId}
                                                                                       whileLoading={<Loading/>}
                                                          bind={{
                                                              project: ({projectId}) => `projects/${projectId}`,
                                                              userRole: ({userId, projectId}) => `roles/${projectId}/${userId}`
                                                          }}>
  {({project = {details: {}}, userRole = {}}) =>
    <Provider value={{...project, project, projectId, userRole}}>
      {children}
    </Provider>
  }
</Firebind>)

export const WithProject = Consumer

export const withProject = (Component) =>
  passedProps =>
    <WithProject>
      {providedProps =>
        <Component {...passedProps} {...providedProps}/>
      }
    </WithProject>
