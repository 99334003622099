// @flow

import 'firebase/storage'
import { firekit } from '@upgrowth/firekit'
import track from './tracking/track'

export async function createInterview(projectId: string, researchItem: { name: string, filename: string }) {
    const database = firekit().database
    const interview = {
        ...researchItem,
        projectId,
        type: 'research',
        userId: firekit().auth.currentUser.uid
    }

    const ref = database.ref(`/projects/${projectId}/research`).push(interview)
    track.event('Create', {category: 'Research', label: projectId})

    return ref.key
}


export function updateTagOnInterview(projectId, researchId, tagKey, value) {
    const promise = firekit().database.ref(`projects/${projectId}/research/${researchId}/tags/${tagKey}`).set(value);
    track.event('Update', {category: 'Research', label: projectId})
    return promise
}

export function updateInterview(researchId, projectId, interview) {
    const promise = firekit().database.ref(`/projects/${projectId}/research/${researchId}`).update(interview)
    track.event('Update', {category: 'Research', label: projectId})
    return promise
}

export function deleteInterview(researchId, projectId) {
    firekit().database.ref(`/projects/${projectId}/research/${researchId}`).set(null)
    track.event('Delete', {category: 'Research', label: projectId})
}

export function interviewStorageRef({projectId}: { projectId: string }) {
    const {app, auth} = firekit()
    return app.storage().ref(`/interviews/${projectId}/${auth.currentUser.uid}`)

}
