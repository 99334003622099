// @flow

import * as React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { firekit } from '@upgrowth/firekit'
import { intersection, map, size } from 'lodash'
import { Modal } from '@upgrowth/react-fulcrum'
import TagView from '../../components/tags/TagView'
import Page from '../../compositions/Page'
import { resolveRoute } from '../../routes'
import CreateInsight from '../../compositions/insights/CreateInsight'
import { withProject } from '../../services/withProject'
import { hasEditorRole } from '../../roles'

class InsightsReport extends React.Component<*> {
  props: {
    projectId: string,
    insights: any[],
    history: any
  }
  state: {
    create: boolean,
    working: boolean,
  } = {
    working: false,
    create: false,
  }

  createInsight = async (form) => {
    const {projectId} = this.props
    const {database} = firekit()
    const path = `projects/${projectId}/insights`

    this.setState({working: true})
    await database.ref(path).push(form)
    this.setState({form: {}, working: false, create: false})
  }

  viewInsight = async (insightId) => {
    const {projectId, history} = this.props
    history.push(resolveRoute('insightEditor', {projectId, insightId}))
  }

  handleAdd = () => this.setState({create: true})

  updateInsightTag = async (insightId, tagId, value) => {
    const {projectId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    await database.ref(`projects/${projectId}/insights/${insightId}/tags`).update({
      [tagId]: value
    })
    this.setState({working: false})
  }
  deleteInsight = async (insightId) => {
    const {projectId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    await database.ref(`projects/${projectId}/insights/${insightId}`).remove()
    this.setState({working: false})
  }

  render () {
    const {project: {insights = {}, tags = {}}, projectId, userRole} = this.props
    const {working, create} = this.state
    const editable = hasEditorRole(userRole)

    return (
      <Page className={'InsightsReport'}>
        <TagView
          className="inset"
          tags={tags}
          items={insights}
          onChange={editable && this.updateInsightTag}
          onAdd={editable && this.handleAdd}
          onClick={this.viewInsight}
          name="Insights"
          rowActions={editable && [
            {label: 'View', action: this.viewInsight},
            {label: 'Edit', action: this.viewInsight},
            {label: 'Delete', action: this.deleteInsight},
          ]}
        />

        <Modal isOpen={create} onRequestClose={() => this.setState({create: false})}>
          <CreateInsight projectId={projectId} onComplete={() => this.setState({create: false})} />
        </Modal>
      </Page>
    )
  }
}

export default withParams(withProject(InsightsReport))