// @flow

import * as React from 'react'
import Card from '../../components/Card'
import { keys, last } from 'lodash'
import { FiCornerDownRight } from 'react-icons/fi'
import { Button } from '@upgrowth/react-fulcrum'
import { NavLink } from '@upgrowth/reactkit'
import { withProject } from '../../services/withProject'
import Icon from '../../elements/icons/Icon'

const ProjectCard = ({onClick, project = {}, projectId, name}) => (
  <Card onClick={onClick}>
    <h3>{project.name || name}</h3>
    <div className="card-icon">
      <Icon icon={"project"}/>
    </div>
    <Button as={NavLink} projectId={projectId} name="project">View</Button>
  </Card>
)

export default withProject(ProjectCard)