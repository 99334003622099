// @flow

import * as React from 'react'
import { forEach, isEmpty, map, mapValues, size, times } from 'lodash'
import { Link, Route, Switch, withParams } from '@upgrowth/reactkit'
import type { History, Location } from 'react-router-dom'
import Page from '../../compositions/Page'
import Interview from '../interviews/Interview'

type Props = { researchId: string, interview: Interview, loading: boolean, history: History, location: Location }


export default class ResearchItem extends React.Component<Props> {
    render() {
        return (
            <Page>
                <Route component={Interview}/>
            </Page>

        )
    }
}
