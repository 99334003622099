// @flow

import './ProjectRoutes.scss'
import * as React from 'react'
import { NavLink, Redirect, Route, Switch, withParams } from '@upgrowth/reactkit'

import Project from '../../pages/projects/Project'
import ProjectList from '../../pages/projects/ProjectList'

import Segments from '../../pages/segments/Segments'
import Settings from '../../pages/settings/Settings'
import Insights from '../../pages/insights/Insights'
import Research from '../../pages/research/Research'
import { Avatar, Flex, NavItem, NavMenu, Row, Select, Sidebar } from '@upgrowth/react-fulcrum'
import { firebind, firekit, withUserId } from '@upgrowth/firekit'
import { map } from 'lodash'
import ProjectNavItems from './ProjectNavItems'
import { MdViewModule } from 'react-icons/md'
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi'
import User from '../../pages/user/User'
import { ProjectProvider } from '../../services/withProject'
import PageHelp from '../help/PageHelp'
import localstorage from 'local-storage'

class ProvideProjectConditionally extends React.PureComponent {
  render () {
    const {children} = this.props
    return (<Switch>
        <Route name='project'
               render={({match: {params: {projectId}}}) =>
                 <ProjectProvider projectId={projectId}>{children}</ProjectProvider>
               } />
        <Route>{children}</Route>
      </Switch>
    )
  }
}

class ProjectRoutes extends React.Component<*> {
  state : {
    sidebarHidden: boolean
  } = {
    sidebarHidden: localstorage.get(`sidebar-hidden`) || false
  }

  show = ()=> {
    this.setState({sidebarHidden: false})
    localstorage.set(`sidebar-hidden`, false)
  }
  hide = ()=> {
    this.setState({sidebarHidden: true})
    localstorage.set(`sidebar-hidden`, true)
  }
  render () {
    const {projects, history} = this.props
    const go = (projectId) => history.push(`/projects/${projectId}`)
    const options = map(projects, (name, key) => ({label: 'View All', value: ''}, {label: name, value: key}))
    const {photoURL} = firekit().auth.currentUser || {}
    const {sidebarHidden} = this.state
    return (
      <ProvideProjectConditionally>
        <Row className={`ProjectRoutes ${sidebarHidden ? 'hide-sidebar' : 'show-sidebar'}`}>
          <Sidebar>
            <NavMenu>
              <div className='header'>
                {photoURL && <NavLink name="user" className={'user-link'}><Avatar img={photoURL} /></NavLink>}
                <NavLink name='projectList' className="projectsLink"><MdViewModule style={{color: '#9dc47e', fontSize: 60, padding: 16}} /></NavLink>
                <Flex />
                <a className='hide-sidebar' onClick={this.hide}>
                  <FiChevronsLeft />
                </a>
              </div>
              <Switch>
                <Route name="project"
                       render={({match: {params: {projectId}}}) => <Select className="ProjectsList"
                                                                           value={projectId} options={options}
                                                                           onChange={go}
                                                                           placeholder="Go to project"
                                                                           variant="transparent" />} />
                <Route render={() => <Select className="ProjectsList" value={undefined} options={options}
                                             onChange={go} placeholder="Go to project"
                                             variant="transparent" />} />
              </Switch>
              <Route name='project' component={ProjectNavItems} />
              <PageHelp />
            </NavMenu>
            {sidebarHidden && <a className='show-sidebar' onClick={this.show}>
              <FiChevronsRight />
            </a>
            }
          </Sidebar>
          <Switch>
            <Route name='project' exact component={Project} />
            <Route name='segments' component={Segments} />
            <Route name='settings' component={Settings} />
            <Route name='insights' component={Insights} />
            <Route name='research' component={Research} />
            <Route name='projectList' exact component={ProjectList} />
            <Route name='user' component={User} />
            <Redirect to="projects" />
          </Switch>
        </Row>
      </ProvideProjectConditionally>
    )
  }
}

export default withUserId(firebind(ProjectRoutes, {
  projects: ({userId}) => `users/projects/${userId}`,
}))
