import * as React from 'react'
import { Button } from '@upgrowth/react-fulcrum'
import { logIn } from '../services/users'
import SingleColumn from '../layouts/SingleColumn'
import { brandName } from '../brand'
import { Switch } from '../App'
import './LogIn.scss'
import copy from 'copy-to-clipboard'
import AboutNav from './about/AboutNav'

const flag = 'chrome://flags/#enable-experimental-web-platform-features'

class CopyButton extends React.PureComponent {
    state = {}

    render() {
        return (
            <Button variant='secondary' onClick={() => copy(this.props.text) && this.setState({copied: true})}>
                {this.state.copied ? 'Copied to Clipboard' : 'Copy to Clipboard'}
            </Button>
        )
    }
}

export default () => (
    <div className='LogIn'>
        <AboutNav empty/>
        <SingleColumn className='variant-skinny' actions={<Button onClick={logIn}>Log in with Google</Button>}>
            <h1>
                Welcome to <strong>{brandName}</strong>,
                the research platform
                that lets you collaborate with others to <em>discover & share</em> powerful insights.
            </h1>

            <div className='notice'>
                <h2><strong>{brandName}</strong> is in public beta.</h2>

                <p>We really appreciate you trying it out and would love to <a href='mailto:hello@itsforest.com'>hear
                    from you</a>.</p>
            </div>

            {!document.createElement('video').audioTracks &&

            <div className='notice warning'>
                <h2>Your browser can't handle videos with multiple audio tracks.</h2>
                <p>This may result in a degraded experience with certain types of video.</p>

                <p>We recommend Safari if you're using a Mac, or you can enable this feature in Google Chrome:</p>
                <ol>
                    <li>In a new tab, go to <b>{flag}</b> <CopyButton text={flag}/></li>
                    <li>Change the highlighted dropdown to <b>Enabled</b></li>
                    <li>Click the <b>RELAUNCH NOW</b> button at the bottom</li>
                </ol>

            </div>
            }
        </SingleColumn>
    </div>
)