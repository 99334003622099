import lightbulb from './lightbulb.svg'
import tag from './tag.svg'
import upload from './upload.svg'
import computerProjects from './computer-projects.jpg'
import computerDesk from './computer-desk.jpg'
import team from './team.jpg'
import computerPrice from './computer-price.jpg'
import captureInsights from './capture-insights.png'
import overview from './overview.png'
import projectTeam from './project-team.png'
import projects from './projects.png'
import researchProcess1 from './research-process-1.png'
import researchProcess2 from './research-process-2.png'
import tags from './tags.png'

const images = {
  lightbulb, tag, upload, computerProjects, computerDesk, team, computerPrice,
  captureInsights, overview, projectTeam, projects, researchProcess1, researchProcess2, tags
}
export const imageUrl = (name) => images[name] || console.error("Can't find image ", name)
export const backgroundImage = (name) => images[name] ? {backgroundImage: `url(${images[name]})`} : {}