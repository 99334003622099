// @flow

import "./Admin.scss"
import React from 'react'
import { FirelightView } from '@upgrowth/firelight'

export default () => <FirelightView
                        firelight='firelight'
                        viewName='main'
                        baseRoute='/admin' />
