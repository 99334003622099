// @flow
import './ScaleTag.scss'
import * as React from 'react'
import { withParams } from '@upgrowth/reactkit'
import { map, trim } from 'lodash'
import { Input } from '@upgrowth/react-fulcrum'
import type { Tag } from './Tag'

export type ScaleTag = {
    options: string[]
} & Tag

class ScaleTagEditor extends React.Component<*> {
    props: {
        tag: Tag,
        value: any,
        onChange: (value: any) => any,
    };

    render() {
        const {value, onChange, tag = {}} = this.props

        return (
            <Input
                className="ScaleTagEditor"
                min={tag.from}
                max={tag.to}
                type="range"
                value={value}
                onChange={onChange}
            />
        )

    }
}

const ScaleTagViewer = ({className, tag: { to }, value}: { className: string, tag: ScaleTag, value: string }) => (
    <h5 className={`ScaleTagViewer ${className}`}>{value} / {to}</h5>
)

export {ScaleTagEditor, ScaleTagViewer}
