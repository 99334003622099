// @flow

import './Research.scss'
import * as React from 'react'
import { NavLink, Route, Switch, withParams } from '@upgrowth/reactkit'
import { intersection, map, size } from 'lodash'
import SiteSection from '../../compositions/SiteSection'
import ResearchReport from './ResearchReport'
import ResearchItem from './ResearchItem'
import MomentsReport from './MomentsReport'

class Research extends React.Component<*> {

    render() {
        const {projectId} = this.props;

        return (
            <SiteSection className="Research">
                <Switch>
                    <Route name='research' component={ResearchReport} exact/>
                    <Route name='researchMoments' component={MomentsReport}/>
                    <Route name='researchItem' component={ResearchItem}/>
                </Switch>
            </SiteSection>
        )
    }
}

export default withParams(Research)