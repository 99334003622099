import { escapeRegExp, reduce } from 'lodash'

const routes = {
    home: '/',
    about: '/about/:pageId',

    projects: '/projects',
    projectList: '/projects',
    project: '/projects/:projectId',

    projectBoards: '/projects/:projectId/boards',
    projectPeople: '/projects/:projectId/people',
    projectAssumptions: '/projects/:projectId/assumptions',

    settings: '/projects/:projectId/settings',
    tags: '/projects/:projectId/settings/tags',
    team: '/projects/:projectId/settings/team',

    insights: '/projects/:projectId/insights',
    insightEditor: '/projects/:projectId/insights/:insightId',

    segments: '/projects/:projectId/segments',
    segment: '/projects/:projectId/segments/:segmentId',
    segmentTags: '/projects/:projectId/segments/:segmentId/tags',

    profile: '/projects/:projectId/segments/:segmentId/profiles/:profileId',

    research: '/projects/:projectId/research',
    researchMoments: '/projects/:projectId/research/moments',
    researchItem: '/projects/:projectId/research/:researchId',


    moments: '/projects/:projectId/research/:researchId/moments',
    moment: '/projects/:projectId/research/:researchId/moments/:momentId',

    user: '/me',
    admin: '/admin',

}

export default routes
export const resolveRoute = (routeName: $Keys<typeof routes>, mapping: { [string]: string }) =>
    reduce(mapping, (subbedPath, value, key) => subbedPath.replace(new RegExp(escapeRegExp(`:${key}?`) + '?'), value), routes[routeName])
