import type { AuthProvider, User } from 'firebase'
// @flow
import firebase from 'firebase'
import { firekit } from '@upgrowth/firekit'
import { getDB } from './util'
import { isEmpty } from 'lodash'
import track from './tracking/track'

export async function logIn(): Promise<User> {
  const provider: AuthProvider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('profile')
  provider.addScope('email')

  const {user} = await firekit().auth.signInWithPopup(provider)
  const {displayName, email, photoURL, uid} = user

  const currentProfile = await getDB(`users/profiles/${uid}`)

  if (isEmpty(currentProfile)) {
    track.create(user)
    track.event('Create', {category: 'Users'})

  } else {
    track.identify(user)
  }

  track.event('Log In', {category: 'Users'})


  await firekit().database.ref('users').update({
    [`profiles/${uid}`]: {displayName, photoURL},
    [`secrets/${uid}`]: {email}
  })
  return user
}

export function logOut() {
  track.event('Log Out', {category: 'Users'})
  track.anonymise()
  return firekit().auth.signOut()
}