// @flow

import './Link.scss'
import * as React from 'react'
import { NavLink } from '@upgrowth/reactkit'
import { FiXCircle } from 'react-icons/fi'
import { withProject } from '../../services/withProject'
import {get} from "lodash"

export type Link = {
  type: 'moment' | 'interview' | 'insight',
  ids: string[]
}

function dataForLink (project, {type, key}) {
  if (type === 'moment') {
    return get(project, ['moments', key])
  } else if (type === 'research') {
    return get(project, ['research', key])
  } else if (type === 'insight') {
    return get(project, ['insights', key])
  }
}

const Link = withProject(({project, projectId, link = {}, clickable = true, onRemove}) => {
  const {type, key} = link
  let researchId = key
  let momentId = key
  let insightId = key
  let name = 'researchItem'
  let data = dataForLink(project, link)

  if(!data){
    // TODO - Deleting something should probably clean up the data that references it so the model remains clean
    // If the data is no longer in the project (i.e. has been moved or deleted) just hide the link
    return null
  }
  if (type == 'moment') {
    name = 'moment'
    researchId = data.researchId
  } else if (type === 'insight') {
    name = 'insightEditor'
  }
  return (
    <div className={`Link type-${link.type}`}>
      {clickable ? <NavLink name={name}
                            projectId={projectId}
                            insightId={insightId}
                            researchId={researchId}
                            momentId={momentId}>
          {data.name || 'Unnamed'}
        </NavLink>
        :
        <span>{data.name || 'Unnamed'}</span>
      }
      {onRemove && <a className="remove" href="javacript:" onClick={() => onRemove(link)}><FiXCircle /></a>}
    </div>
  )
})
export default Link
