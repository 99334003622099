// @flow

import './MomentList.scss'
import { isEmpty, map, size, pickBy } from 'lodash'
import { Link, Route } from '@upgrowth/reactkit'
import * as React from 'react'
import { withRouter } from 'react-router'
import { resolveRoute } from '../../routes'
import { deleteMoment, updateMomentTag } from '../../services/moments'
import TagView from '../../components/tags/TagView'
import CreateInsight from '../../compositions/insights/CreateInsight'
import { Modal } from '@upgrowth/react-fulcrum'
import { withProject } from '../../services/withProject'
import type { UserRole } from '../../database'
import { hasEditorRole } from '../../roles'

class MomentList extends React.Component {
  state: {
    modal: boolean,
    include: {},
  } = {
    modal: false,
    include: {}
  }

  viewMoment = (momentId) => {
    const {researchId, history, projectId} = this.props
    history.push(resolveRoute('moment', {
      projectId,
      momentId,
      researchId
    }))
  }

  tagMoment = async (momentId, tag, value) => {
    const {projectId, moments} = this.props
    const {researchId} = moments[momentId]
    await updateMomentTag(momentId, researchId, projectId, tag, value)
  }

  deleteMoment = async (momentId) => {
    const {researchId, projectId} = this.props
    if (window.confirm('Are you sure you want to delete this moment?')) {
      await deleteMoment(momentId, researchId, projectId)
    }
  }
  deleteMoments = async (momentIds) => {
    const {researchId, projectId} = this.props
    if (window.confirm(`Are you sure you want to delete the ${size(momentIds)} selected moments?`)) {
      await Promise.all(map(momentIds, momentId => deleteMoment(momentId, researchId, projectId)))
    }
  }
  createInsightBulk = (momentIds) => {
    const {researchId, projectId} = this.props
    const include = map(momentIds, (id) => ({
      type: 'moment',
      key: id
    }))
    include.push({
      type: 'research',
      key: researchId
    })
    this.openModal(include)
  }

  createInsight = (momentId) => {
    const {researchId, projectId} = this.props
    const include = [{
      type: 'moment',
      key: momentId
    }, {
      type: 'research',
      key: researchId
    }]
    this.openModal(include)
  }

  openModal = (include = {}) => {
    this.setState({modal: true, include})
  }
  closeModal = () => {
    this.setState({modal: false, include: {}})
  }

  render () {
    let {actions, moments, researchId, history, projectId, project: {tags}, userRole} = this.props
    if (researchId) {
      moments = pickBy(moments, {researchId})
    }
    const editable = hasEditorRole(userRole)

    return (
      <div className='MomentList insights'>
        <div className='moments'>
          {isEmpty(moments)
            ? <div>
              <p>When you listen through the interview, focus group or user-testing session and find an
                important
                moment, use the <em>Capture</em> and <em>Finish</em> buttons below to define when it
                starts
                and
                ends.</p>

              <p>This will automatically launch the Insights Composer to begin synthesising the data. When
                you
                are
                finished, the insight will appear here.</p>
            </div>
            : <TagView tags={tags}
                       items={moments}
                       onClick={this.viewMoment}
                       onChange={editable && this.tagMoment}
                       rowActions={editable && [
                         {label: 'View', action: this.viewMoment},
                         {label: 'Create Insight', action: this.createInsight},
                         {label: 'Delete', action: this.deleteMoment},
                       ]}
                       bulkActions={editable &&  [
                         {label: 'Create Insight', action: this.createInsightBulk},
                         {label: 'Delete selected', action: this.deleteMoments},
                       ]}
            />
          }
          <Modal isOpen={this.state.modal} onRequestClose={this.closeModal}>
            <CreateInsight projectId={projectId} onComplete={this.closeModal} include={this.state.include} />
          </Modal>
        </div>
        <div className='moment-controls'>
          {actions}
        </div>
      </div>
    )
  }
}

/*
<CardStack>
    {map(moments, (moment: MomentType, momentId) => (
        <MomentCard key={momentId}
                    moment={moment}
                    projectTags={projectTags || {}}
                    onDelete={() => window.confirm("Are you sure you want to delete this moment?") && deleteMoment(momentId, researchId, projectId)}
                    onClick={() => history.push(resolveRoute('moment', {
                        projectId,
                        momentId,
                        researchId
                    }))}/>
    ))}
</CardStack>
 */

export default withRouter(withProject(MomentList))
