// @flow

import { map, reduce } from 'lodash'
import { firekit } from '@upgrowth/firekit'

export const flattenNestedObject = (obj: any, path: string[] = [], separator: string = '/'): {[string]: any} =>
  typeof obj === 'object'
    ? reduce(obj, (out: {[string]: any}, v: any, k: string) => ({
      ...out,
      ...flattenNestedObject(v, [...path, k], separator)
    }), {})
    : {[path.join(separator)]: obj}

export const optionifyObject = (obj: {[key: string]: string} | string[]) => map(obj, (label, value) => ({value, label}))
export const optionifyArray = (ary: string[] = []) => ary.map(value => ({value, label: value}))

export const deoptionifyToObject = (options: {label: string, value: any}[]) => (
  reduce(options, (out, {label, value}) => (
    value ? {...out, [value]: label} : out
  ), {})
)

export function idify(id: string): string {
  return id.toLowerCase().replace(/, /g, '--').replace(/[^a-z0-9\-]+/ig, '-')
}

export function initials(displayName: string = "") {
  return displayName.replace(/(\b[a-zA-Z])[a-zA-Z]* ?/, "$1")
}

const emailValidRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function isValidEmail(email: string = "") {
  return !!email.match(emailValidRegexp)
}

export const getDB = async (path) => (await firekit().database.ref(path).once('value')).val()
