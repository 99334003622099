// @flow
import './SimpleDropdown.scss'
import * as React from 'react'
import { chain, every, find, get, map, size } from 'lodash'
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown'
import * as FeatherIcons from 'react-icons/fi'
import { FiX } from 'react-icons/fi'

class SimpleDropdown extends React.Component {
    props: {
        controlled?: boolean,
        variant?: string
    }
    state: {
        open?: boolean
    } = {
        open: undefined
    }
    onClose = () => {
        const {ref} = this.refs
        if (ref) {
            ref.hide()
        }
        if(this.props.controlled){
            this.setState({open: false})
        }
    }

    onOpen = ()=>{
        if(this.props.controlled){
            this.setState({open: true})
        }
    }

    render() {
        const {label, title, children,  className = "", icon = "FiChevronDown", controlled, ...props} = this.props;
        const {open} = this.state
        const Icon = FeatherIcons[icon] || FeatherIcons["FiChevronDown"]
        return (
            <Dropdown ref="ref" className={`SimpleDropdown ${className}`} {...props} active={open}>

                <DropdownTrigger onClick={this.onOpen}>
                    {label} <Icon className="dropdown__indicator" icon={icon} size="16"/>
                </DropdownTrigger>

                <DropdownContent>
                    <div className="header">
                        {title && <h5>{title}</h5>}
                        <a className="close" href="javascript:" onClick={this.onClose}><FiX/></a>
                    </div>
                    {children}
                </DropdownContent>
            </Dropdown>
        )
    }
}

export default SimpleDropdown
