// @flow

import * as React from 'react'
import { debounce, forEach, map } from 'lodash'
import { RouteObserver } from '@upgrowth/reactkit'
import track from './track'

/**
 * Page tracking with v4 react-router sucks - a component which you stick
 * at the root of the router and will tracking any time props change.
 *
 * <Router><TrackPageView>... routes</TrackPageView></Router>
 */
export class TrackPageView extends React.Component<*, *> {
  componentDidMount () {
    this.routeChanged(window.location.pathname)
  }

  routeChanged = debounce((location: string) => track.page(location), 400)

  render () {
    return <RouteObserver onRouteChange={(location) => this.routeChanged(location)} children={this.props.children} />
  }
}
