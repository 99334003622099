// @flow
import './InsightEditor.scss'
import * as React from 'react'
import { Label, Switch } from '@upgrowth/react-fulcrum'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { firekit } from '@upgrowth/firekit'
import { filter, get, keys, map, pickBy, without } from 'lodash'
import Loading from '../../components/Loading'
import Page from '../../compositions/Page'
import RenderEntity from '../../compositions/entity/RenderEntity'
import Link from '../../elements/insights/Link'
import TagTable from '../../components/tags/TagTable'
import { withProject } from '../../services/withProject'
import { TagEditor, TagViewer } from '../../elements/tags/Tag'
import { hasEditorRole } from '../../roles'

class InsightEditor extends React.Component<*> {
  props: {
    insight: {
      name: string,
      description: string,
      tags: {}
    },
    insightId: string,
    projectId: string
  } = {}

  state: {
    working: boolean,
    isEditing: boolean
  } = {
    working: false,
    isEditing: false
  }

  addTag = async (tag) => {
    const {projectId, insightId} = this.props
    const {database} = firekit()
    const path = `projects/${projectId}/insights/${insightId}/tags`
    this.setState({working: true})
    await database.ref(path).update({[tag]: true})
    this.setState({form: {}, working: false})
  }

  updateTag = async (tagId, value) => {
    const {projectId, insightId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    await database.ref(`projects/${projectId}/insights/${insightId}/tags/${tagId}`).set(value)
    this.setState({working: false})
  }

  updateInsight = async (value) => {
    const {projectId, insightId} = this.props
    const {database} = firekit()
    this.setState({working: true})
    await database.ref(`projects/${projectId}/insights/${insightId}/`).update(value)
    this.setState({working: false})
  }

  toggleEditing = () => {
    const {isEditing} = this.state
    this.setState({isEditing: !isEditing})
  }

  addInclude = async (key) => {
    const {project: {moments, insights, research}, projectId, insightId} = this.props
    const items = {...moments, ...insights, ...research}
    const item = items[key]
    if (item) {
      const {type} = item
      const {database} = firekit()
      const path = `projects/${projectId}/insights/${insightId}/links/${key}`
      this.setState({working: true})
      await database.ref(path).update({key, type})
      this.setState({working: false})
    }
  }
  removeInclude = async (remove) => {

    const {key} = remove
    const {projectId, insightId} = this.props
    const {database} = firekit()

    const path = `projects/${projectId}/insights/${insightId}/links/${key}`
    this.setState({working: true})
    await database.ref(path).set(null)
    this.setState({working: false})

  }

  render () {
    const {working, isEditing} = this.state
    const {project: {moments, research, insights}, tags = {}, projectId, insightId, userRole} = this.props
    const editable = hasEditorRole(userRole)
    const insight = get(insights, [insightId])

    if (!insight || !tags) {
      return <Loading />
    }

    const items = {...moments, ...insights, ...research}

    return (
      <Page className="InsightEditor" size="normal">
        {editable && <Switch className="edit-mode-toggle" value={isEditing} onChange={this.toggleEditing}
                             label="Edit"
                             variant="light" />
        }

        <RenderEntity
          isEditing={editable && isEditing}
          entity={insight}
          tags={tags}
          onChange={this.updateInsight}
          canAddTags
          fieldOptions={{
            name: true,
            description: true
          }}
        />

        <div className='insight-links'>
          {
            isEditing ?
              <React.Fragment>
                <Label variant="primary">Links</Label>
                <div className="links">
                  {map(insight.links, (link, key) =>
                    <Link
                      projectId={projectId}
                      key={key}
                      link={link}
                      clickable={false}
                      onRemove={this.removeInclude}
                    />
                  )}
                </div>

                <TagTable
                  items={items}
                  types={true}
                  content={true}
                  tags={tags}
                  columns={{name: true, description: true, type: true}}
                  onClick={this.addInclude}
                />
              </React.Fragment>
              :
              <React.Fragment>
                <Label variant="primary">Links</Label>
                <div className="links">
                  {map(insight.links, (link, key) =>
                    <Link
                      projectId={projectId}
                      key={key}
                      link={link}
                      clickable={true}
                    />
                  )}
                </div>
              </React.Fragment>
          }
        </div>
      </Page>
    )
  }
}

export default withParams(withProject(InsightEditor))
