// @flow
import * as React from 'react'
import { withParams } from '@upgrowth/reactkit'
import { map, trim } from 'lodash'
import { Input } from '@upgrowth/react-fulcrum'
import type { Tag } from './Tag'

export type TextTag = {
} & Tag

class TextTagEditor extends React.Component<*> {
    prop: {
        tagKey: string,
        tag: Tag,
        value: any,
        onChange: (value: any) => any,
    };

    render() {
        const {value, onChange, tag = {}} = this.props

        return <Input
                    placeholder={tag.name}
                    value={value}
                    onChange={onChange}
                />
    }
}


const TextTagViewer = ({className, tag, value}: {className: string, tag: TextTag, value: string}) => (
        <h5 className={`TextTagViewer ${className}`}>{value}</h5>
)

export {TextTagEditor, TextTagViewer}
