// @flow

import './AboutNav.scss'
import React from 'react'
import { Button, Navbar, NavMenu } from '@upgrowth/react-fulcrum'
import logo from '../../components/acorn-copy.svg'
import { brandName } from '../../brand'
import { NavLink } from '@upgrowth/reactkit'
import Flex from '@upgrowth/react-fulcrum/lib/compositions/layout/Flex'
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown'
import menuIcon from './menu.svg'
import { firebind } from '@upgrowth/firekit'
import { map } from 'lodash'

const scrollAware = (awake, Component) =>
  class ScrollAware extends React.PureComponent {
    state = {awake: this.awakeness()}

    componentDidMount () {
      window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount () {
      window.removeEventListener('scroll', this.handleScroll)
    }

    awakeness () {
      return window.scrollY >= awake
    }

    handleScroll = () => {
      if (this.state.awake !== this.awakeness()) {
        this.setState({awake: this.awakeness()})
      }
    }

    render () {
      return <Component {...this.props} awake={this.state.awake} />
    }
  }

const AboutNav = ({pageId, awake, empty = false, navItems}) => (
  <Navbar variant='dark' className={`AboutNav ${awake ? 'stuck' : ''}`}>
    <NavLink name='home' className='brand'>
      <img src={logo} alt={brandName} />
      <div className='brand-name-header'>Forest</div>
    </NavLink>
    {!empty &&
    <Dropdown key={pageId}>
      <DropdownTrigger>
        <img src={menuIcon} />
      </DropdownTrigger>
      <DropdownContent>
        <NavMenu>

          <div className='items'>
            {map(navItems, ({value}, key) => {
              const {label, name} = value
              return <NavLink key={key} name='about' pageId={name}>{label}</NavLink>
            })}
          </div>


          <Flex />

          <div className='actions'>
            <Button as={NavLink} name='projectList' variant='outline'>Start a free project</Button>
          </div>

        </NavMenu>
      </DropdownContent>
    </Dropdown>
    }

  </Navbar>
)

export default firebind(scrollAware(57, AboutNav), {
  navItems: 'firelight/content/current/config/contentNav'
})