// @flow

import * as React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { firekit } from '@upgrowth/firekit'
import { map, mapValues, trim } from 'lodash'
import type { Tags } from '../../elements/tags/Tag'
import { resolveRoute } from '../../routes'
import TagView from '../../components/tags/TagView'
import Page from '../../compositions/Page'
import { Modal } from '@upgrowth/react-fulcrum'
import CreateProfile from '../../compositions/segments/CreateProfile'
import type { UserRole } from '../../database'
import { hasEditorRole } from '../../roles'

class SegmentOverview extends React.Component<*> {
    props: {
        projectId: string,
        segmentId: string,
        segment: {},
        tags: Tags,
        userRole: UserRole,
        history: any,
    }
    state: {
        create: boolean,
        working: boolean,
        current: string,
    } = {
        create: false,
        working: false,
        current: "",
    }

    updateTag = async (key, tag, value) => {
        const {projectId, segmentId} = this.props;
        const {database} = firekit();
        this.setState({working: true});
        const path = `projects/${projectId}/segments/${segmentId}/profiles/${key}/tags/${tag}`
        await database.ref(path).set(value);
        this.setState({working: false})
    }

    viewProfile = (profileId: string)=> {
        const {history, projectId, segmentId} = this.props
        history.push(resolveRoute('profile', {projectId, segmentId, profileId}))
    }

    deleteProfile = async (profileId: string)=> {
        const {projectId, segmentId} = this.props;
        const {database} = firekit();
        this.setState({working: true});
        const path = `projects/${projectId}/segments/${segmentId}/profiles/${profileId}`
        await database.ref(path).set(null);
        this.setState({working: false})
    }

    handleAdd = ()=> this.setState({create: true})

    render() {
        const {segment = {}, tags = {}, userRole, projectId, segmentId } = this.props
        const { create } = this.state;
        const segmentTags = mapValues(segment.tags, (_, key) => tags[key])
        const editable = hasEditorRole(userRole)
        return (
            <Page className="SegmentOverview">
                <TagView
                    className="inset"
                    tags={segmentTags}
                    items={segment.profiles}
                    onClick={this.viewProfile}
                    onAdd={editable && this.handleAdd}
                    onChange={editable && this.updateTag}
                    name={segment.name}
                    rowActions={editable && [
                        { label: 'View', action: this.viewProfile },
                        { label: 'Delete', action: this.deleteProfile }
                    ]}
                />
                <Modal isOpen={create} onRequestClose={() => this.setState({create: false})}>
                    <CreateProfile projectId={projectId} segmentId={segmentId} onComplete={() => this.setState({create: false})}/>
                </Modal>
            </Page>
        );
    }
}


export default withParams(SegmentOverview)
