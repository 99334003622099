// @flow

import './CreateProfile.scss'
import * as React from 'react'
import type { History } from 'react-router-dom'
import type { UploadTask } from 'firebase'
import { Button, Flex, Form, FormRow } from '@upgrowth/react-fulcrum'
import { firekit } from '@upgrowth/firekit'

class CreateProfile extends React.Component<{ projectId: string, onComplete: (key: string) => any }, { name?: string, progress?: number, error?: string }> {
    state: {
        working: boolean
    } = {
        working: false
    }

    handleSubmit = async (form: {}) => {

        const {projectId, segmentId, onComplete} = this.props;
        const {database} = firekit();
        const value = {name: "Unknown"}
        const path = `projects/${projectId}/segments/${segmentId}/profiles`
        const ref = await database.ref(path).push({...form, type: 'profile'});
        onComplete && onComplete(ref.key)
    }


    render() {
        const {working} = this.state

        return (
            <div className="CreateProfile">
                <h1>Create Profile</h1>
                <p>Profiles make up your user or customer segments. Profiles are aggregate representation of your
                    product or services intended users</p>

                <Form onSubmit={this.handleSubmit}
                      disabled={working}
                      fields={[
                          {name: "name", type: "text", validation: "required", placeholder: "Create a new Profile", autoComplete: "off"},
                      ]}
                >
                    <FormRow>
                        <Flex/>
                        <Button type="submit">Create New Profile</Button>
                    </FormRow>
                </Form>
            </div>

        )
    }

}

export default CreateProfile
