// @flow

import './index.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { firekit } from '@upgrowth/firekit'
import { getDB } from './services/util'
import track from './services/tracking/track'

function transformConfig (config) {
  if (config.authDomain === 'forest-prod.firebaseapp.com') {
    return {...config, authDomain: 'itsforest.com'}
  } else {
    return config
  }
}

async function initApp () {
  try {
    const firebaseConfiguration = transformConfig(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG))
    // Initialise FireKit

    firekit(firebaseConfiguration)
    // Import compile time configuration
    const config = await getDB(`config/public`)
    track.init(config.tracking)
    ReactDOM.render(<App />, document.getElementById('root'))
  } catch (e) {
    console.error('Failed to initialise ', e)
    throw e
  }
}

initApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
