// @flow

import './ResearchItemTags.scss'
import { Form, Row } from '@upgrowth/react-fulcrum'
import { TagEditor } from '../../elements/tags/Tag'
import React from 'react'
import { chain, get, map, pickBy } from 'lodash'
import AddTag from '../tags/AddTag'
import RenderEntity from '../../compositions/entity/RenderEntity'
import type { UserRole } from '../../database'
import { hasEditorRole, hasRole, ROLE_ADMIN, ROLE_EDITOR } from '../../roles'

const EmptyForm = {tag: ''}

class ResearchItemTags extends React.Component {
  props: {
    userRole: UserRole
  }
  state: {
    form: {
      tag: string
    }
  } = {
    form: EmptyForm
  }
  updateTag = async (tag, value) => {
    const {item} = this.props
    let {tags = {}} = item
    const update = {...item, tags: {...tags, [tag]: value}}

    await this.handleUpdate(update)
    this.setState({form: EmptyForm})
  }

  handleUpdate = async (update) => {
    const {onUpdate} = this.props
    return onUpdate(update)
  }

  render () {
    const {userRole, tags, item} = this.props
    const isEditing = hasEditorRole(userRole)
    return (
      <RenderEntity entity={item} tags={tags} isEditing={isEditing} canAddTags={isEditing} onChange={this.handleUpdate} />
    )
  }
}

export default ResearchItemTags
