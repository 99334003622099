// @flow

import './Interview.scss'
import * as React from 'react'
import ReactPlayer from 'react-player/lib/players/FilePlayer'
import ScrubBar from './ScrubBar'
import { first, forEach, get, isEmpty, keyBy, keys, map, mapValues, size, times, values } from 'lodash'
import type { Project } from '../../database'
import { Button } from '@upgrowth/react-fulcrum'
import { createMoment, toTimeCode, trackBeginMomentCapture, trackCancelMomentCapture, updateMomentTag } from '../../services/moments'
import { Link, NavLink, Redirect, Route, Switch, withParams } from '@upgrowth/reactkit'
import Moment from '../moments/Moment'
import MomentList from '../moments/MomentList'
import { resolveRoute } from '../../routes'
import type { History, Location } from 'react-router-dom'
import pauseIcon from './pause.svg'
import playIcon from './play.svg'
import forwardIcon from './forward.svg'
import rewindIcon from './rewind.svg'
import muteIcon from './mute.svg'
import unmuteIcon from './unmute.svg'
import loopIcon from './loop.svg'
import stopLoopIcon from './stop-loop.svg'
import track from '../../services/tracking/track'
import Tabs from '../../components/Tabs'
import NavItem from '@upgrowth/react-fulcrum/lib/compositions/nav/NavItem'
import ResearchItemDetails from '../../components/research/ResearchItemTags'
import { updateInterview } from '../../services/interviews'
import { withProject } from '../../services/withProject'
import Hotkeys from 'react-hot-keys'
import SplitPane from 'react-split-pane'
import localstorage from 'local-storage'
import { hasRole, ROLE_ADMIN, ROLE_EDITOR } from '../../roles'

const PlayState = {
  play: 'play',
  pause: 'pause',
  buffer: 'buffer'
}

type Props = { projectId: string, researchId: string, project: Project, loading: boolean, history: History, location: Location }
type State = {
  current: number,
  length: number,
  ready: boolean,
  loaded: number,
  muted: boolean,
  captureStart?: number,
  captureEnd?: number,
  currentAudioTrack: number,
  audioTrackCount: number,
  playbackRate: number,
  lockToRegion: boolean,
  playState: $Keys<typeof PlayState>
}

//
// const IfMoment = ({children, notMoment, moments}: {
//   children: ({ moment: Moment, momentId: string }) => React.Node,
//   notMoment: () => React.Node,
//   moments: { [momentId: string]: MomentType }
// }) => (
//   <Switch>
//     <Route name='moment' render={({match: {params: {momentId}}}) => children({moment: moments[momentId], momentId})} />
//     <Route render={notMoment} />
//   </Switch>
// )

class ViewInterviewRaw extends React.Component<Props, State> {
  state: State = {
    ready: false,
    current: 0,
    audioTrackCount: 0,
    currentAudioTrack: 0,
    muted: false,
    length: 0,
    loaded: 0,

    lockToRegion: true,
    playbackRate: 1,
    playState: PlayState.pause
  }

  refs = {
    player: ReactPlayer
  }

  momentId ({location: {pathname}} = this.props): string | void {
    const match = pathname.match(/\/moments\/([^/?]+)/)
    if (match) {
      return match[1]
    }
  }

  handleScrub = (ratio: number) => {
    this.trackEvent('Scrub')
    this.refs.player.seekTo(ratio)
  }

  trackEvent = (eventName) => {
    const {project, projectId, researchId} = this.props
    const player = this.refs.player
    const video: HTMLVideoElement = player.getInternalPlayer()
    const {videoHeight, videoWidth} = video || {videoHeight: 0, videoWidth: 0}
    const timecode = player.getCurrentTime()
    const duration = player.getDuration()

    const interview = project.research[researchId]

    track.event(eventName, {
      label: projectId,
      category: 'Interviews',
      duration,
      fileSize: interview.fileSize,
      videoHeight,
      videoWidth,
      ratio: duration !== 0 ? timecode / duration : 0,
      timecode
    })
  }

  switchAudioTracks (trackIndex: number) {
    const audioTracks = this.refs.player.getInternalPlayer().audioTracks
    if (!audioTracks || audioTracks.length === 0) {
      return
    }
    times(audioTracks.length, i => {
      console.log('Setting audio track', i, i === trackIndex)
      audioTracks[i].enabled = i === trackIndex
    })
    if (this.state.currentAudioTrack !== trackIndex) {
      this.trackEvent(`Audio Track ${trackIndex}`)

      this.setState({currentAudioTrack: trackIndex})
    }
  }

  handleDuration = (length: number) => {
    if (this.state.length !== length) {
      this.setState({length})
    }
    this.jumpToStartOfMoment()

  }

  handleProgress = ({playedSeconds, loaded}: { playedSeconds: number, loaded: number }) => {

    let {playState, captureStart, captureEnd, lockToRegion} = this.state
    let captureData = {}
    if (this.isCapturing()) {

      if (captureStart && playedSeconds < captureStart) {
        captureStart = playedSeconds
      } else {
        captureEnd = playedSeconds
      }

      captureData = {captureStart, captureEnd}
    }

    const momentId = this.momentId()

    if (momentId && lockToRegion && this.state.playState === PlayState.play) {
      const moments = this.props.project.moments || {}
      let moment = moments[momentId]
      if (playedSeconds >= moment.timeEnd) {
        this.jumpToStartOfMoment()
      }
    }

    this.setState({current: playedSeconds, loaded, ...captureData})
  }

  shiftHead = (relativeSecs: number) => {
    const current = this.refs.player.getCurrentTime()
    const {length} = this.state
    const newRatio = Math.max(0, Math.min((current + relativeSecs) / length, 1))
    this.handleScrub(newRatio)
  }

  isCapturing = () => typeof this.state.captureStart === 'number'

  toggleCapture = () => {
    const {projectId} = this.props
    const player = this.refs.player
    const capturing = !this.isCapturing()
    const captureStart = capturing ? player.getCurrentTime() : undefined
    const captureEnd = capturing ? captureStart : undefined
    if (capturing) {
      trackBeginMomentCapture(player.getCurrentTime() / player.getDuration(), projectId)
    } else {
      trackCancelMomentCapture(player.getCurrentTime() / player.getDuration(), projectId)
    }
    this.setState({captureStart, captureEnd})
  }

  finishCapture = async () => {
    const {captureStart, captureEnd} = this.state
    const {researchId, history, projectId, project} = this.props
    const interview = project.research[researchId]

    if (captureStart !== undefined && captureEnd !== undefined) {
      const fileId = first(keys(interview.files))
      const momentId = await createMoment(projectId, researchId, {timeStart: captureStart, timeEnd: captureEnd, fileId, type: "video"})
      history.push(resolveRoute('moment', {projectId, momentId, researchId}))
      this.setState({captureStart: undefined, captureEnd: undefined})
    }
  }

  playState = mapValues(PlayState, state => () => {
    this.trackEvent({play: 'Play', pause: 'Pause', buffer: 'Buffering'}[state])
    this.setState({playState: state, playbackRate: 1})
  })

  keyActions = {
    "space": ()=>this.togglePlay(),
    "left": ()=>this.shiftHead(-1),
    "right": ()=>this.shiftHead(1),
    "shift+left": ()=>this.shiftHead(-10),
    "shift+right": ()=>this.shiftHead(10),
    "shift+up": ()=>this.handleScrub(0),
    "shift+down": ()=>this.handleScrub(0.9999),
  }


  fastReview = () => {
    this.trackEvent('Rewind')
    this.shiftHead(-10)
  }

  review = () => this.shiftHead(-2)
  fastForward = () => {
    this.trackEvent(`Fast Forward`)

    this.setState({playbackRate: 3})
  }

  mute = () => {
    this.trackEvent(`Mute`)

    this.setState({muted: true})
  }
  unmute = () => {
    this.trackEvent(`Unmute`)
    this.setState({muted: false})
  }

  handleReady = () => {
    this.trackEvent('Load')

    const audioTracks = this.refs.player.getInternalPlayer().audioTracks

    if (audioTracks) {
      window.pl = this.refs.player.getInternalPlayer()
      const audioTrackCount = audioTracks.length

      this.setState({ready: true, audioTrackCount, currentAudioTrack: audioTrackCount - 1})

    } else {
      console.warn('Audio tracks support not found. If you can\'t hear anything, it\'s probably this.')
      console.warn('To enable it in Firefox, enable media.track.enabled in about:config')
      console.warn('To enable it in Chrome, enable this: chrome://flags/#enable-experimental-web-platform-features and restart.')
      this.setState({ready: true})
    }

  }

  handleStart = () => {
    this.trackEvent(`Start`)
    this.playState.play()
    setTimeout(() => this.switchAudioTracks(this.state.currentAudioTrack), 100)
  }

  componentDidUpdate (oldProps: Props) {
    const momentId = this.momentId()
    if (this.momentId(oldProps) !== momentId) {
      this.jumpToStartOfMoment()
    }
  }

  jumpToStartOfMoment () {
    const momentId = this.momentId()
    const moment = get(this.props.project.moments, momentId)
    if (moment) {
      const {length} = this.state
      console.log('Scrubbing to ', moment.timeStart / length, moment.timeStart, length)
      this.handleScrub(moment.timeStart / length)

    }
  }

  unlockRegion = () =>
    this.setState({lockToRegion: false})

  lockRegion = () =>
    this.setState({lockToRegion: true})

  togglePlay = () => this.state.playState === PlayState.play ? this.playState.pause() : this.playState.play()

  handleError = (error) => {
    console.error('Playback error', error)
    this.trackEvent('Error')
  }

  updateItem = async (item) => {
    const {researchId, projectId} = this.props
    console.log("Update Item ", item)
    await updateInterview(researchId, projectId, item)
  }


  handleKeyDown = (keyName, e, handle)=>{
    const action = this.keyActions[keyName]
    const ignore = ["input", "textarea", "button", "a"].indexOf(e.target.localName) > -1
    if(action && !ignore){
      e.preventDefault()
      e.stopImmediatePropagation()
      e.stopPropagation()
      this.keyActions[keyName] && this.keyActions[keyName]()
      // console.log("Key down ", keyName, this.keyActions[keyName])
    }
  }
  render () {
    const {project, userRole, researchId, loading, projectId, tags = {}} = this.props
    const {lockToRegion, length, current, captureStart, captureEnd, playState, playbackRate, ready, muted, audioTrackCount, currentAudioTrack} = this.state
    if (!project.research) { return null }
    const interview = project.research[researchId]
    const moments = project.moments || {}
    const momentId = this.momentId()
    const moment = momentId && moments[momentId] || {}
    if (loading) {
      return null
    }
    if(!interview){
      return <Redirect name="research" from='/' projectId={projectId}/>
    }
    const {mediaUrl} = first(values(interview.files)) || {}
    const online = true // I just use this to not destory my data cap on the train. Delete if you want
    const splitValue = localstorage.get(`researchitem-split-value-${researchId}`)

    const isEditor = hasRole(userRole, ROLE_EDITOR, ROLE_ADMIN)
    const actions = isEditor ? <>
      <Button onClick={this.toggleCapture}
              className={this.isCapturing() ? 'dangerous' : ''}>
        {this.isCapturing() ? <><span>Capturing&nbsp;</span><span
          className='time-code'> {toTimeCode(captureEnd - captureStart)}</span></> : 'Capture moment'}
      </Button>
      <Button onClick={this.finishCapture} disabled={!this.isCapturing()}>
        Finish moment
      </Button>
    </> : null

    return (
      <SplitPane className='Interview'
                 split="vertical"
                 minSize={200}
                 maxSize={-100}
                 defaultSize={splitValue || "50%"}
                 onChange={ size => localStorage.setItem(`researchitem-split-value-${researchId}`, size) }>

        <div className='ResearchItemData'>
          <Tabs>
            <NavItem as={NavLink} name="researchItem" projectId={projectId} researchId={researchId} exact>Details</NavItem>
            <NavItem as={NavLink} name="moments" projectId={projectId} researchId={researchId}>Moments</NavItem>
          </Tabs>
          <Switch>
            <Route name='moment' render={({history, match: {params: {momentId}}}) => (
              <Moment showTutorial={size(moments) === 1} key={momentId} momentId={momentId}
                      history={history} researchId={researchId} moment={moment}
                      projectId={projectId} tags={tags}
                      userRole={userRole} />
            )} />
            <Route name='moments' render={() => (
              <MomentList researchId={researchId}
                          moments={moments}
                          projectId={projectId}
                          actions={actions} />
            )} />
            <Route name='researchItem' render={() => <ResearchItemDetails tags={tags} item={interview} onUpdate={this.updateItem} userRole={userRole}/>} />
          </Switch>
        </div>
        <Hotkeys
          keyName={Object.keys(this.keyActions).join(",")}
          onKeyDown={this.handleKeyDown}
        >
        <div className='Interview-player'>
          <div className='player-wrapper'>
            <ReactPlayer ref="player"
                         url={online && mediaUrl}
                         onClick={this.togglePlay}
                         loop={!moment && lockToRegion}
                         playing={playState === PlayState.play}
                         playbackRate={playbackRate}
                         muted={muted}
                         volume={1}
                         width='100%'
                         height="480px"
                         onReady={this.handleReady}
                         onPlay={this.playState.play}
                         onStart={this.handleStart}
                         onPause={this.playState.pause}
                         onBuffer={this.playState.buffer}
                         onEnded={this.playState.pause}
                         onError={this.handleError}

                         progressInterval={100}
                         onProgress={this.handleProgress}

                         onDuration={this.handleDuration} />

            <div className='transport-controls-wrapper'>
              {audioTrackCount > 1 && <div className='audio-track-picker'>
                <img src={unmuteIcon} />{times(audioTrackCount, n => <button key={n}
                                                                             onClick={() => this.switchAudioTracks(n)}
                                                                             disabled={currentAudioTrack === n}>{n}</button>)}
              </div>}

              <div className='transport-controls'>
                <button onClick={this.fastReview}><img src={rewindIcon} /></button>
                {/*<button onClick={this.review}>◀️ 2s</button>*/}

                {playState === PlayState.pause && <button onClick={this.playState.play} disabled={!ready}>
                  <img style={{height: '26px', width: '26px'}} src={playIcon} /></button>}
                {playState === PlayState.play &&
                <button onClick={this.playState.pause}><img style={{height: '26px', width: '26px'}}
                                                            src={pauseIcon} /></button>}
                {/*{playState === PlayState.buffer && <button onClick={this.playState.pause}>⤵️</button>}*/}
                <button onClick={this.fastForward}><img src={forwardIcon} /></button>
                {/*<button onClick={this.skipForward}>⏭</button>*/}
                {muted
                  ? <button onClick={this.unmute}><img src={muteIcon} /></button>
                  : <button onClick={this.mute}><img src={unmuteIcon} /></button>
                }
              </div>
              <div className='loop-button'>
                {lockToRegion
                  ? <button onClick={this.unlockRegion}><img src={loopIcon} /></button>
                  : <button onClick={this.lockRegion}><img src={stopLoopIcon} /></button>
                }
              </div>
            </div>
          </div>


          <ScrubBar length={length}
                    start={moment ? moment.timeStart : captureStart}
                    end={moment ? moment.timeEnd : captureEnd}
                    current={current}
                    onScrub={this.handleScrub} />


        </div>
        </Hotkeys>

      </SplitPane>
    )
  }
}

export { ViewInterviewRaw }
export default withParams(withProject(ViewInterviewRaw))

