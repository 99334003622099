// @flow

import * as React from 'react'
import { Button, Flex, Form, FormRow } from '@upgrowth/react-fulcrum'
import { find, last, map, without } from 'lodash'
import { createProject } from '../../services/projects'

const defaultTags = {
  gender: {
    name: 'Gender',
    type: 'select',
    options: [
      'Female',
      'Male',
      'Prefer not to say',
      'Unknown',
      'Other'
    ]
  },
  age: {
    name: 'Age',
    type: 'select',
    options: [
      '18-24',
      '25-34',
      '35-44',
      '45-54',
      '55-64',
      '65+',
      'Unknown',
      'Other',
    ]
  },
  channel: {
    name: 'Channel',
    type: 'multiselect',
    options: [
      'Web App',
      'iOS App',
      'Android App',
      'Call Center',
      'Unknown',
      'Other',
    ]
  },
  sentiment: {
    name: 'Sentiment',
    type: 'scale',
    from: '0',
    to: '5'
  },
  emotion: {
    name: 'Emotion',
    type: 'multiselect',
    options: [
      'Anger',
      'Anticipation',
      'Anxiety',
      'Confident',
      'Confused',
      'Courage',
      'Contempt',
      'Desire',
      'Despair',
      'Disgust',
      'Disinterested',
      'Embarrassed',
      'Envy',
      'Excited',
      'Happy',
      'Hope',
      'Indignent',
      'Fear',
      'Frustrated',
      'Guilt',
      'Grief',
      'Love',
      'Joy',
      'Happy',
      'Pain',
      'Panic',
      'Pity',
      'Pleasure',
      'Sad',
      'Shame',
      'Surprised',
      'Trust',
      'Wonder',
    ],
  },
  userType: {
    name: 'User Type',
    type: 'select',
    options: [
      'Customer',
      'Staff',
      'Administrator',
      'Other',
    ]
  },
  assignedTo: {
    name: 'Assigned to',
    type: 'user'
  },
}

class CreateProject extends React.Component {
  props: {
    onComplete: (id: string) => any
  }
  state: {
    working: boolean,
  } = {
    working: false,
  }

  createProject = async (form: {}) => {
    const {onComplete} = this.props
    this.setState({working: true})
    const id = await createProject({details: form, tags: defaultTags})
    this.setState({working: false})
    onComplete && onComplete(id)
  }

  render () {
    const {working} = this.state
    return (
      <div className={`CreateProject`}>
        <h1>Create a new Project</h1>
        <Form onSubmit={this.createProject}
              disabled={working}
              fields={[{name: 'name', label: 'Project Name', autoComplete: 'off'}]}
        >
          <FormRow>
            <Flex />
            <Button type="submit">Create Project</Button>
          </FormRow>

        </Form>
      </div>
    )
  }
}

export default CreateProject
