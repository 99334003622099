// @flow

import './Team.scss'
import React from 'react'
import { Avatar, Button, Input } from '@upgrowth/react-fulcrum'
import { withParams } from '@upgrowth/reactkit'
import { isEmpty, map } from 'lodash'
import type { Profile } from '../../database'
import { firebind } from '@upgrowth/firekit'
import { cancelInvite, ejectUser, inviteUser } from '../../services/projects'
import Modal from '../../layouts/Modal'
import { isValidEmail } from '../../services/util'
import InlineDelete from '../../components/InlineDelete'
import Page from '../../compositions/Page'
import { withProject } from '../../services/withProject'
import { hasEditorRole } from '../../roles'

const ProjectUserAvatar = firebind(({profile: {photoURL} = {}, name, userId, projectId}: { profile: Profile }) =>
    <Avatar img={photoURL} alt={name}>
      <InlineDelete
        onClick={() => window.confirm(`Really remove ${name} from the project?`) && ejectUser(userId, projectId)} />
    </Avatar>
  , {
    profile: ({userId}) => `users/profiles/${userId}`
  })

class Team extends React.PureComponent {
  state = {modal: false}

  newInvite = () => this.setState({modal: {email: ''}})

  sendInvite = () => {
    inviteUser(this.state.modal.email, this.props.projectId, (this.props.project.details || {}).name)
    this.dismissInviteModal()
  }

  dismissInviteModal = () => this.setState({modal: false})

  render () {
    const {modal} = this.state
    const {projectId, project: {users, invites}, userRole} = this.props
    const editable = hasEditorRole(userRole)
    return (
      <Page className='Team' size="small">
        <h3>Project Team</h3>
        <div className='team-avatars'>
          {map(users, (name, userId) => <ProjectUserAvatar key={userId} {...{name, userId, projectId}} />)}
        </div>
        <h3>Invites</h3>
        {isEmpty(invites) && <p className='notice'>There are no outstanding invites.</p>}
        {map(invites, (email, k) => <div className='Invitee' key={k}>{email}
          <button onClick={() => cancelInvite(email, projectId)}>x</button>
        </div>)}
        <Modal isOpen={modal} actions={<>
          <Button onClick={this.sendInvite} disabled={!isValidEmail(modal.email)}>Invite</Button>
          <Button onClick={this.dismissInviteModal}>Cancel</Button>
        </>}>
          <Input type='email' value={modal.email} onChange={(email) => this.setState({modal: {email}})} label='Email Address' />
        </Modal>

        {editable && <Button onClick={this.newInvite}>Send new invite</Button>}
      </Page>
    )
  }
}

export default withParams(withProject(Team))
