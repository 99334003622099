// @flow

import './Segment.scss'
import * as React from 'react'
import { NavLink, Route, Switch, withParams } from '@upgrowth/reactkit'
import { map, trim } from 'lodash'
import SegmentTags from './SegmentTags'
import SegmentOverview from './SegmentOverview'
import { NavItem } from '@upgrowth/react-fulcrum'
import Tabs from '../../components/Tabs'
import Page from '../../compositions/Page'
import { withProject } from '../../services/withProject'
import type { Project, UserRole } from '../../database'

type Props = {
  projectId: string,
  segmentId: string,
  project: Project,
  userRole: UserRole
}

const Segment = ({project: {segments = {}, tags = {}}, userRole, projectId, segmentId, loading}: Props) => {
  const segment = segments[segmentId]
  const passProps = {segment, tags, projectId, segmentId, userRole}
  return (
    <Page className="Segment">
      <Tabs className={'segments'}>
        <NavItem as={NavLink} name="segment" exact projectId={projectId} segmentId={segmentId}>Profiles</NavItem>
        {/*<NavItem as={NavLink} name="segmentTags" exact  projectId={projectId} segmentId={segmentId}>Detail</NavItem>*/}
        <NavItem as={NavLink} name="segmentTags" projectId={projectId} segmentId={segmentId}>Details</NavItem>
      </Tabs>
      {!loading &&
      <Switch>
        <Route name='segment' exact render={() => <SegmentOverview {...passProps} />} />
        <Route name='segmentTags' render={() => <SegmentTags {...passProps} />} />
      </Switch>
      }
    </Page>
  )
}

export default withParams(withProject(Segment))
