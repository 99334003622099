// @flow

import * as React from 'react'
import { withParams } from '@upgrowth/reactkit'
import { map, size, trim } from 'lodash'
import { Select } from '@upgrowth/react-fulcrum'
import type { Tag } from './Tag'

export type MultiSelectTag = {
  options: string[]
} & Tag

class MultiSelectTagEditor extends React.Component<*> {
  prop: {
    tagKey: string,
    tag: Tag,
    value: any,
    onChange: (value: any) => any,
    options: []
  }

  handleChange = (value: string[], e: any) => {
    const {onChange} = this.props
    // If you push an empty array to firebase, it acts as a delete, so we replace an empty array with an empty string
    onChange(value.length ? value : '', e)
  }

  render () {
    const {value, onChange, tag = {}, options} = this.props
    const tagOptions = options || map(tag.options, (label, index) => ({label, value: label}))

    return <Select isMulti={true} value={value} onChange={this.handleChange} options={tagOptions} placeholder={`Select a ${tag.name}`} />
  }
}

const MultiSelectTagViewer = ({className, tag, value = []}: { className: string, tag: MultiSelectTag, value: string[] }) => (
  <React.Fragment>
    {
      Array.isArray(value)
        ? <h5 className={`MultiSelectTagViewer multi ${className}`}>{map(value, (v, key) => <span key={key}>{v}</span>)}</h5>
        : <h5 className={`MultiSelectTagViewer ${className}`}>{value && value.length && <span>{value}</span>}</h5>
    }
  </React.Fragment>
)

export { MultiSelectTagEditor, MultiSelectTagViewer }
