// @flow

import * as React from 'react'
import { firebind, withUserId } from '@upgrowth/firekit'
import { map } from 'lodash'
import { Modal } from '@upgrowth/react-fulcrum'
import { resolveRoute } from '../../routes'
import ProjectCard from './ProjectCard'
import CardGrid from '../../compositions/CardGrid'
import Page from '../../compositions/Page'
import Card from '../../components/Card'
import SiteSection from '../../compositions/SiteSection'
import CreateProject from '../../compositions/projects/CreateProject'
import { FiPlus } from 'react-icons/fi'

class ProjectList extends React.Component {
  props: { projects: { [projectId: string]: string } }

  state: {
    create: boolean
  } = {
    create: false
  }
  openModal = () => this.setState({create: true})
  closeModal = () => this.setState({create: false})

  projectCreated = (projectId) => {
    const {history} = this.props
    this.closeModal()
    history.push(resolveRoute('project', {projectId}))
  }

  render () {
    const {projects, history} = this.props
    const {create} = this.state
    return (
      <SiteSection className='ProjectList'>
        <Page size="large">
          <h2>Projects</h2>
          <CardGrid>
            {map(projects, (projectName, projectId) => (
              <ProjectCard key={projectId}
                           name={projectName}
                           projectId={projectId}
              />
            ))}
            <Card onClick={this.openModal}>
              <h3>Create</h3>
              <div className="card-icon">
                <FiPlus />
              </div>
            </Card>
          </CardGrid>

          <Modal isOpen={create} onRequestClose={this.closeModal}>
            <CreateProject onComplete={this.projectCreated} />
          </Modal>
        </Page>
      </SiteSection>
    )
  }
}

export default withUserId(firebind(ProjectList, {
  projects: ({userId}) => `users/projects/${userId}`
}))