// @flow

import React from 'react'
import './Column.scss'

const DFColumn = ({children, actions, className, as = 'div', ...props}: {className: string, children: React.Node, actions: React.Node}) => {
  const As = as
  return (
    <As className={`DFColumn ${className}`} {...props}>
      <div className='content'>
        {children}
      </div>
      <div className='actions'>
        {actions}
      </div>
    </As>
  )
}
export default DFColumn