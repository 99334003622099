// @flow
import './TagViewControls.scss'
import * as React from 'react'
import { chain, find, first, get, groupBy, indexOf, map, size } from 'lodash'
import { Button, NavItem, Switch } from '@upgrowth/react-fulcrum'
import TagViewFilter from './TagViewFilter'
import SimpleDropdown from '../../elements/SimpleDropdown'
import { FiEye, FiTag } from 'react-icons/fi'
import Icon from '../../elements/icons/Icon'

const GroupByDropdown = ({value, onChange, tags}) => {
    const options = chain(tags).sortBy('name').value()
    const tag = find(tags, {key: value}) || {}
    return (
        <SimpleDropdown className={"GroupByDropdown variant-outline"} label={`Group by: ${tag.name}`}>
            {map(options, ({name, key}) => <NavItem key={key} onClick={()=>onChange(key)}>{name}</NavItem>)}
        </SimpleDropdown>
    )
}

const FilterDropdown = ({filters, tags, active, onChange}) => {
    const count = size(filters);
    const label = <React.Fragment><Icon icon="filter"/>Filter</React.Fragment>
    return (
        <SimpleDropdown className={`FilterDropdown variant-outline ${count ? 'filtered' : 'unfiltered'}`} label={label} controlled={true}>
            <TagViewFilter filters={filters} tags={tags} onChange={onChange}/>
        </SimpleDropdown>
    )
}

const ColumnsDropdown = ({value = {}, tags, onChange, filtered = false}) => {
    return (
        <SimpleDropdown className={`ColumnsDropdown variant-outline ${filtered ? 'filtered' : 'unfiltered'}`} icon="FiTag" title="Show/Hide Tags">
            <a className="reset" onClick={()=>onChange(undefined)}>Reset</a>
            {map(tags, ({name, key}) => <Switch key={key} value={value[key] === false ? false : true} onChange={(visible)=>onChange({...value, [key]: visible})} label={name} variant="primary"/>)}
        </SimpleDropdown>
    )
}
const ActionsDropdown = ({actions, itemId}) => (
    <SimpleDropdown className={"ActionsDropdown"} label="" icon="ellipsis-v">
        {map(actions, ({label, action}, index) => <a key={index} href="javascript:" onClick={() => action(itemId)}>{label}</a>)}
    </SimpleDropdown>
)

const AddButton = ({onClick}) => <Button className="AddButton" onClick={onClick}><Icon icon="add"/></Button>
const FilteredCount = ({count = 0, total = 0}) => <div className="FilteredCount TagViewControl">{count}/{total}</div>

const TagViewControls = ({className = "", children}) => (
    <div className={`TagViewControls ${className}`}>
        {children}
    </div>
)


const ExtraActions = ({children}) => {
  return (
    <SimpleDropdown className={"ExtraActions variant-none"} icon="FiMoreVertical" title="Actions">
      {children}
    </SimpleDropdown>
  )
}

export {TagViewControls, ActionsDropdown, FilterDropdown, GroupByDropdown, FilteredCount, ColumnsDropdown, AddButton, ExtraActions}
