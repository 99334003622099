// @flow

import * as React from 'react'
import { debounce, forEach, map } from 'lodash'
import { RouteObserver } from '@upgrowth/reactkit'
import GaTrackingStrategy from './GaTrackingStrategy'
import { Event, Strategy, User } from './TrackingTypes'
import MixpanelStrategy from './MixpanelTrackingStrategy'

// TODO - These should return promises - not necessarily wait for tracking
// // implementations, but promise would make it easier to consume
class Track {
  strategies: {} = {}
  ready = false
  backlog = {
    page: [],
    event: [],
    create: [],
    identify: [],
    anonymise: []
  }

  paused = false

  init (strategies: { [string]: any }) {
    forEach(strategies, (config, strategy) => {
      // console.log(`Initialising ${strategy} tracking`)
      const strat = this.strategies[strategy]
      if (strat) {
        strat.init(config)
      } else {
        console.log(`Strategy ${strategy} unknown`)
      }
    })
    this.ready = true
    this.trackDeferred()
  }

  trackDeferred = () => {
    forEach(this.backlog, (queue, method) => {
      while (queue.length) {
        const {args} = queue.shift()
        this.track(method, ...args)
      }
    })
  }

  trackOrDefer = (method: string, ...args) => {
    if (!this.ready) {
      // console.log(`Deferring tracking ${method} %s`, ...args)
      this.backlog[method].push({args})
    } else {
      this.track(method, ...args)
    }
  }
  track = (method: string, ...args) => {
    try {
      // console.log(`Tracking ${method} %s`, ...args)
      map(this.strategies, strategy => strategy[method](...args))
    } catch (err) {
      console.error(`Tracking ${method}  failed: `, err)
    }
  }

  adEvent (event, size, campaignId) {
    this.event(event, {category: `ad-${size}`, label: campaignId})
  }

  pausePageTracking() {
    this.paused = true
  }

  resumePageTracking() {
    this.paused = false
  }

  page (page: string) {
    (!this.paused) && this.trackOrDefer('page', page)
  }

  event (name: string, event: Event) {
    this.trackOrDefer('event', name, event)
  }

  create (user: User) {
    this.trackOrDefer('create', user)
  }

  identify (user: User) {
    this.trackOrDefer('identify', user)
  }

  anonymise () {
    this.trackOrDefer('anonymise')
  }

  addStrategy (name: string, strategy: Strategy) {
    this.strategies[name] = strategy
  }
}

const track = new Track()
track.addStrategy('ga', new GaTrackingStrategy())
// track.addStrategy('autopilot', new AutopilotTrackingStrategy())
track.addStrategy('mixpanel', new MixpanelStrategy())
export default track
