// @flow

import './CreateResearchItem.scss'
import * as React from 'react'
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton'
import type { History } from 'react-router-dom'
import type { FirebaseError, UploadTask } from 'firebase'
import { Button, Row, Flex } from '@upgrowth/react-fulcrum'
import { brandName } from '../../brand'
import { createInterview, interviewStorageRef } from '../../services/interviews'
import RenderEntity from '../entity/RenderEntity'
import type { Tags } from '../../elements/tags/Tag'
import { cloneDeep, first, set } from 'lodash'
import { encode } from 'firebase-key'
import {FiChevronRight} from "react-icons/fi"

class CreateResearchItem extends React.Component<{ projectId: string, onComplete: (key: string) => any, tags: Tags }, { name?: string, progress?: number, error?: string }> {
  state: {
    form: any,
    step: 'details' | 'upload',
    working: boolean
  } = {
    form: {
      name: '',
      description: '',
      tags: {},
      files: {}
    },
    step: 'details',
    working: false
  }

  handleStart = (file: File) => {
    const form = cloneDeep(this.state.form)
    set(form, ['files'], {[encode(file.name)]: {name: file.name, filename: '', originalFilename: file.name}})
    this.setState({form})
  }

  handleProgress = (progress: number) => {
    this.setState({progress})
  }

  handleError = (error: FirebaseError) => {
    console.error(error)
    this.setState({error, uploaded: false})
  }

  handleComplete = async (filename: string, task: UploadTask) => {
    const {projectId} = this.props
    const form = cloneDeep(this.state.form)
    const storageRef = interviewStorageRef({projectId}).child(filename)
    const mediaUrl = await storageRef.getDownloadURL()
    const {size} = await storageRef.getMetadata()
    const {files} = form
    const fileKey = first(Object.keys(files))
    form.files[fileKey] = {...form.files[fileKey], filename, size, storageUrl: storageRef.toString(), mediaUrl}
    console.info(`Successfully uploaded ${fileKey || '<undefined>'} as ${filename}`)
    this.setState({form})
    await this.handleCreateInterview()
  }

  handleCreateInterview = async () => {
    const {projectId, onComplete} = this.props
    const {form} = this.state
    this.setState({working: true})
    const researchId = await createInterview(projectId, form)
    this.setState({working: false})
    onComplete && onComplete(researchId)
  }

  render () {
    const {tags} = this.props
    const {error, progress, form, working, step} = this.state

    return (
      <div className='CreateResearchItem'>
        <h1>Create a Research Item</h1>
        <p>
          Upload an interview, session or workshop as a video or audio file, and annotate snippets
          to help you extract information and synthesise insights.
        </p>

        {step === 'details' && <RenderEntity
          entity={form}
          isEditing
          onChange={(form) => this.setState({form})}
          onSubmit={() => this.setState({step: 'upload'})}
          disabled={working}
          tags={tags}
          canAddTags>
          <Row>
            <Flex/>
          <Button type="submit">Next<FiChevronRight/></Button>
          </Row>
        </RenderEntity>
        }
        {step === 'upload' &&
        <React.Fragment>
          <p>
            Right now we let you upload interview recordings - more coming soon! Upload an MP4,
            M4A or MP3 recording
          </p>
          <CustomUploadButton accept="video/mp4, audio/mpeg, audio/mp3, audio/m4a, .mp4, .mp3, .m4a"
                              randomizeFilename
                              className="upload-button"
                              onUploadStart={this.handleStart}
                              onUploadSuccess={this.handleComplete}
                              onProgress={this.handleProgress}
                              onUploadError={this.handleError}
                              storageRef={interviewStorageRef(this.props)}>
            <div className='progress' style={{width: `${progress}%`}} />
            <div className='name'>{progress > 0 ? 'Uploading' : 'Click to upload'}</div>
          </CustomUploadButton>
        </React.Fragment>
        }
        {error && <div className='error'>Error: {error.message}</div>}
      </div>
    )
  }
}

export default CreateResearchItem
