// @flow

import * as React from 'react'
import { NavLink, Route, Switch, withParams } from '@upgrowth/reactkit'
import { map, trim } from 'lodash'
import ProjectTags from '../tags/ProjectTags'
import Team from '../team/Team'
import SiteSection from '../../compositions/SiteSection'

class Settings extends React.Component<*> {

    render() {
        const {projectId} = this.props
        return (
            <SiteSection className="Settings">
                <Switch>
                    <Route name='tags' component={ProjectTags}/>
                    <Route name='team' component={Team}/>
                </Switch>
            </SiteSection>
        );
    }
}


export default withParams(Settings)
