// @flow
import './TagTable.scss'
import * as React from 'react'
import { chain, every, find, get, groupBy, isEmpty, keys, map, mapValues, pickBy, reduce, size, truncate, isEqual } from 'lodash'
import type { Entities, Tags } from '../../elements/tags/Tag'
import { TagViewer } from '../../elements/tags/Tag'
import { Button, Flex, Input, Row } from '@upgrowth/react-fulcrum'
import type { FilterEntry, Filters } from './TagViewFilter'
import { FilterPredicates } from './TagViewFilter'
import { ActionsDropdown, AddButton, ColumnsDropdown, ExtraActions, FilterDropdown, FilteredCount, TagViewControls } from './TagViewControls'
import { exportEntities } from '../../services/excel'

export const findTagsPresentInItems = (items, tags) => {
    const tagsPresentInAnyItem = chain(items)
        .flatMap((item) => keys(pickBy(item.tags, size)))
        .uniq()
        .value()

    // console.log("Showing the following columns by default", tagsPresentInAnyItem)
    const columns = reduce(tags, (result, _, key) => ({
        ...result,
        [key]: tagsPresentInAnyItem.indexOf(key) >= 0
    }), {})
    return columns
}
class TagTable extends React.Component<*> {
    props: {
        className?: string,
        tags: Tags,
        items: Entities,
        onAdd?: () => any,
        onClick?: (itemKey: string) => any,
        actions?: React.Node,
        rowActions?: {},
        bulkActions?: {},
        types?: boolean,

    };
    state: {
        defaultColumns?: { [string]: boolean },
        columns?: { [string]: boolean },
        filters: Filters,
        selected: {
            [string]: boolean
        }
    } = {
        columns: undefined,
        filters: [],
        selected: {}
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {items, tags} = nextProps
        if (prevState.columns === undefined && size(tags)) {
            // Initialise column visibility to match any tags present across all items
            const columns = findTagsPresentInItems(items, tags)
            return {columns, defaultColumns: columns}
        }
        return null
    }

    updateFilter = (filters: Filters) => {
        this.setState({filters, selected: {}});
    }
    updateColumns = (columns: {}) =>  this.setState({columns})

    select = (key: string, selected: boolean) => {
        this.setState({selected: {...this.state.selected, [key]: selected}})
    }
    selectAll = (selected: boolean) => {
        const {items} = this.props
        this.setState({selected: mapValues(items, () => selected)})
    }

    render() {
        const {className = "", tags, items, onAdd, onClick, actions, rowActions, bulkActions, types = false, content = false, name = ""} = this.props;
        const {filters, columns = {}, defaultColumns = {}} = this.state;
        const matchesFilter = (item) => every(filters, (filter: FilterEntry) => {
            const currentValue = get(item, filter.tagKey);
            const predicate = FilterPredicates[filter.operation];
            return predicate(filter.value, currentValue)
        });

        const availableTags = chain(tags).map((value, key) => ({...value, key})).sortBy('name').value();
        const filteredAndSorted = chain(items)
            .map((value, key) => ({key, ...value}))
            .filter(matchesFilter)
            .value();

        const selectedItems = keys(pickBy(this.state.selected, (value) => value))
        return (
            <div className={`TagTable ${className}`}>

                <TagViewControls>
                    {actions}
                    <Flex/>
                    <FilteredCount count={size(filteredAndSorted)} total={size(items)}/>
                    <FilterDropdown filters={filters} tags={availableTags} onChange={this.updateFilter}/>
                    <ColumnsDropdown value={columns} tags={availableTags} onChange={this.updateColumns} filtered={!isEqual(defaultColumns, columns)}/>
                    <ExtraActions>
                        <a onClick={()=>exportEntities(filteredAndSorted, tags, columns, name, "xlsx")}>Export to Excel</a>
                        <a onClick={()=>exportEntities(filteredAndSorted, tags, columns, name, "csv")}>Export to CSV</a>
                    </ExtraActions>
                    {onAdd && <AddButton onClick={onAdd}/> }
                </TagViewControls>

                <Row className="table-container">
                    <table style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <td className={`actions ${bulkActions ? 'bulk' : ''}`}>
                                <div className="actions-inner">
                                    {bulkActions && <Input type="checkbox" onChange={this.selectAll}
                                                           value={size(selectedItems) === size(filteredAndSorted)}/>}
                                    {bulkActions && size(selectedItems) > 0 &&
                                    <ActionsDropdown actions={bulkActions} itemId={selectedItems}/>}
                                </div>
                            </td>
                            {types && <td>Type</td>}
                            <td>Name</td>
                            <td>Description</td>
                            {map(availableTags, ({name, key}) => columns[key] === false ? null :
                                <td key={key}>{name}</td>)}
                        </tr>
                        </thead>
                        <tbody>
                        {map(filteredAndSorted, ({name, description, type, key, tags = {}}) => (
                            <tr key={key}>
                                <td className={`actions ${bulkActions ? 'bulk' : ''}`}>
                                    <div className="actions-inner">
                                        {bulkActions && <Input type="checkbox" value={this.state.selected[key] || false}
                                                               onChange={(value) => this.select(key, value)}/>}
                                        {rowActions && <ActionsDropdown actions={rowActions} itemId={key}/>}
                                    </div>
                                </td>
                                {types &&
                                <td>
                                    {type}
                                </td>
                                }
                                <td>
                                    {onClick ? <a href="javascript:" onClick={() => onClick(key)}>{name}</a> : name}
                                </td>
                                <td>
                                    {truncate(description, {length: 48})}
                                </td>
                                {map(availableTags, ({key, ...tag}, index) => columns[key] === false ? null :
                                    <td key={index}><TagViewer tag={tag} value={tags[key]}/></td>)}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Row>

            </div>
        );
    }
}

export default TagTable;
