// @flow
import './Tags.scss'
import * as React from 'react'
import { map, mapValues, pickBy } from 'lodash'
import type { Tag } from '../../elements/tags/Tag'

type Props = {
    tags: Tag[],
    renderTag: (tag: Tag, key: string) => any,
    className: string
};

export default ({tags, renderTag, className}: Props) => {

    return (
        <div className={`Tags ${className}`}>
            {map(tags, (tag, key) => renderTag(tag, key))}
        </div>
    )

};
