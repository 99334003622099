// @flow

import './Page.scss'
import * as React from 'react'

const Page = ({ className = "", size = "large", children, ...props}) => {
    return (
        <div className={`Page ${className} size-${size}`} {...props}>
            <div className="content">
                {children}
            </div>
        </div>

    );
}


export default Page
