// @flow

import './TagView.scss'
import * as React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { map, mapValues, trim } from 'lodash'
import { Select } from '@upgrowth/react-fulcrum'
import type { Entities, Tags } from '../../elements/tags/Tag'
import TagTable from './TagTable'
import TagBoard from './TagBoard'
import localstorage from 'local-storage'
import { MdViewColumn, MdViewHeadline } from 'react-icons/md'
import type { Project } from '../../database'
import { withProject } from '../../services/withProject'

class TagView extends React.Component<*> {
    props: {
        className?: string,
        tags: Tags,
        items: Entities,
        onAdd?: () => any,
        onClick?: (itemKey: string) => any,
        actions?: React.Node,
        rowActions?: {},
        defaultView?: string,
    }
    state: {
        view: "table" | "board",
    } = {
        view: "table"
    }

    componentDidMount() {
        const {defaultView = "table"} = this.props
        const {pathname} = window.location
        const view = localstorage.get(`view-${pathname}`) || defaultView
        this.setState({view});
    }

    switchView = (view: string) => {
        const {pathname} = window.location
        localstorage.set(`view-${pathname}`, view)
        this.setState({view});
    }


    render() {
        const {view} = this.state
        const {actions, className = "", ...props} = this.props

        const switchView =
            <div className="switch-view">
                { view === "table" ? <MdViewHeadline className="icon"/>: <MdViewColumn className="icon"/> }
                <Select
                    className="toggle-tag-view size-medium"
                    variant="outline"
                    value={view}
                    options={[{label: "Board", value: "board"}, {label: "Table", value: "table"}]}
                    onChange={this.switchView}
                    clearable={false}
                />
            </div>

        const View = view === "table" ? TagTable : TagBoard
        return <View className={`TagView ${className}`} {...props}
                     actions={<React.Fragment>{switchView}{actions}</React.Fragment>}/>
    }
}


export default withProject(TagView)
