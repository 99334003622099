// @flow

import React from 'react'
import deleteIcon from './inline-delete.svg'
import './InlineDelete.scss'

const InlineDelete = ({confirm, onClick}) => (
  <button className='InlineDelete'
          onMouseDown={(e) => e.stopPropagation()} onClick={onClick}>
    <img alt="delete" src={deleteIcon}/>
  </button>
)

export default InlineDelete


// onClick={confirm ? () => window.confirm(confirm) && onClick() : (e) => {
//   console.log("Hey buddy", onClick)
//   onClick(e)
