// @flow

import * as React from 'react'
import { withParams } from '@upgrowth/reactkit'
import { firekit } from '@upgrowth/firekit'
import { join, map, trim } from 'lodash'
import { Button, Form } from '@upgrowth/react-fulcrum'
import type { Tag } from '../../elements/tags/Tag'
import { TagTypes } from '../../elements/tags/Tag'
import Page from '../../compositions/Page'
import { TagList } from '../../components/tags/TagList'
import Modal from '@upgrowth/react-fulcrum/lib/compositions/layout/Modal'
import { withProject } from '../../services/withProject'
import { hasEditorRole } from '../../roles'

export const TagOptions = map(TagTypes, (type) => ({label: type.name, value: type.type}))

const scaleFields = () => [
  {name: 'from', type: 'number', validation: 'required', placeholder: 'From', defaultValue: '0', label: 'Lowest value for this scale tag'},
  {name: 'to', type: 'number', validation: 'required', placeholder: 'To', defaultValue: '5', label: 'Highest value for this scale tag'},
]
const selectFields = () => [
  {name: 'options', type: 'string', validation: 'required', placeholder: 'Comma separated list', label: 'Options for this select tag'},
]
const multiselectFields = () => [
  {name: 'options', type: 'string', validation: 'required', placeholder: 'Comma separated list', label: 'Options for this multiselect tag'},
]
const noFields = () => []
const fields = {
  scale: scaleFields,
  select: selectFields,
  multiselect: multiselectFields,
}

const DefaultForm = {
  name: '',
  type: ''
}

class ProjectTags extends React.Component<*> {
  props: {
    projectId: string,
    tags: {
      [string]: Tag
    }
  }
  state: {
    modal?: 'edit' | 'create',
    working: boolean,
    form: Tag,
    editing?: string,
  } = {
    modal: undefined,
    working: false,
    form: DefaultForm,
    editing: ''
  }

  createTag = async (tag) => {
    const {projectId} = this.props
    const {database} = firekit()
    const path = `projects/${projectId}/tags`

    this.setState({working: true})
    if (tag.options) {
      tag.options = tag.options.split(',').map((v) => trim(v)).filter((v) => v.length > 1)
    }
    await database.ref(path).push(tag)
    this.setState({form: DefaultForm, working: false, modal: undefined, editing: undefined})
  }
  updateTag = async (id, form) => {
    const {tags, projectId} = this.props
    const tag = tags[id]
    const {database} = firekit()
    const path = `projects/${projectId}/tags/${id}`

    this.setState({working: true})
    if (form && form.options) {
      form.options = form.options.split(',').map((v) => trim(v)).filter((v) => v.length > 1)
    }
    const update = form ? {...tag, ...form} : null
    await database.ref(path).set(update)
    this.setState({form: DefaultForm, working: false, modal: undefined, editing: undefined})
  }

  editTag = async (id) => {
    const {tags} = this.props
    const tag = tags[id]
    const form = {...tag}
    if (form.options) {
      form.options = join(form.options, ', ')
    }
    this.setState({form, modal: 'edit', editing: id})
  }

  removeTag = async (id) => {
    if (window.confirm('Are you sure you want to delete this tag? This should only be done if this tag isn\'t used anywhere')) {
      return this.updateTag(id, null)
    }
  }

  closeModal = () => this.setState({modal: undefined, form: DefaultForm})
  openModal = () => this.setState({modal: 'create'})

  render () {
    const {tags, userRole} = this.props
    const {form, working, modal, editing} = this.state
    const editable = hasEditorRole(userRole)
    const additionalFieldsFunction = fields[form.type]
    const additionalFields = additionalFieldsFunction ? additionalFieldsFunction() : []
    return (
      <Page className="ProjectTags" size="small">
        <h3>Project Tags</h3>
        <TagList tags={tags} onRemove={editable && this.removeTag} onEdit={editable && this.editTag} />
        { editable && <Button onClick={this.openModal}>Create Tag</Button>}
        <Modal isOpen={modal} onRequestClose={this.closeModal}>
          <h3>Create a new tag</h3>
          <Form onSubmit={(form) => modal === 'create' ? this.createTag(form) : this.updateTag(editing, form)}
                value={form}
                disabled={working}
                onChange={(form) => this.setState({form})}
                fields={[
                  {name: 'name', label: 'Tag name', type: 'text', validation: 'required', placeholder: 'Tag name'},
                  {
                    name: 'type',
                    label: 'Tag type',
                    type: 'select',
                    validation: 'required',
                    placeholder: 'Tag type',
                    options: TagOptions
                  },
                  ...additionalFields
                ]}
                layout={[
                  ['name', 'type']
                ]}>
            <Button type={'submit'}>Create</Button>
          </Form>
        </Modal>
      </Page>
    )
  }
}

export default withParams(withProject(ProjectTags))
