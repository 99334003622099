// @flow
import './TagViewFilter.scss'
import * as React from 'react'
import { chain, endsWith, every, find, first, get, includes, isEmpty, isEqual, keys, map, mapValues, size, startsWith, toLower } from 'lodash'
import { Button, Input, Select, Row, Flex } from '@upgrowth/react-fulcrum'
import type { TagWithKey } from '../../elements/tags/Tag'
import {FiX} from "react-icons/fi"
export type FilterOperations = "equals" | "notEquals" | "contains" | "starts" | "ends" | "isEmpty" | "isNotEmpty"
export type FilterEntry = {
    tagKey: string,
    value: any,
    operation?: FilterOperations
}
export type Filters = FilterEntry[]

const filterOperations = {
    "equals": {label: "Equals", predicate: (value, data) => isEqual(toLower(data), toLower(value))},
    "notEquals": {label: "Doesn't equal", predicate: (value, data) => !isEqual(toLower(data), toLower(value))},
    "contains": {label: "Contains", predicate: (value, data) => includes(toLower(data), toLower(value))},
    "starts": {label: "Starts with", predicate: (value, data) => startsWith(toLower(data), toLower(value))},
    "ends": {label: "Ends with", predicate: (value, data) => endsWith(toLower(data), toLower(value))},
    "isEmpty": {label: "Is empty", predicate: (value, data) => isEmpty(data)},
    "isNotEmpty": {label: "Is not empty", predicate: (value, data) => !isEmpty(data)},
}

export const FilterPredicates = mapValues(filterOperations, 'predicate')
const filterOperationOptions = map(filterOperations, ({label}, value) => ({label, value}))

const newFilter = {
    tagKey: undefined,
    value: "",
    operation: "contains"
}
const FilterRow = ({filter, tags, onChange}) => {
    const {tagKey, value = "", operation} = filter
    let options = chain(tags)
        .map(({name, key}) => ({label: name, value: `tags.${key}`}))
        .filter(({key}) => key !== filter.tagKey)
        .value()


    return (
        <div className="FilterRow">
            <Select className="tag" value={tagKey}
                    onChange={(tagKey) => onChange({...filter, tagKey})} options={options}
                    clearable={false}/>
            <Select className="operation" value={operation} onChange={(operation) => onChange({...filter, operation})}
                    options={filterOperationOptions} clearable={false}/>
            <Input className="value" value={value} onChange={(value) => onChange({...filter, value})}
                   placeholder="Value..."/>
            <a href="javascript:" className="remove" variant="light" onClick={() => onChange(null)}><FiX/></a>
        </div>
    )
}

class TagViewFilter extends React.Component {
    props: {
        filters: Filters,
        tags: Array<TagWithKey>,
        onChange: (filters: Filters) => any,
        types?: boolean,
        content?: boolean
    }

    addNewRow = () => {
        const {filters, onChange, tags} = this.props
        if (onChange) {
            const tagKey = tags[0].key
            onChange([...filters, {...newFilter, tagKey}])
        }
    }

    updateRow = (filter: FilterEntry, index: number) => {
        const {filters, onChange} = this.props
        const updated = [...filters]
        if (filter) {
            updated[index] = filter
        } else {
            updated.splice(index, 1)
        }
        console.log("Updating ", filters, " to ", updated)
        onChange && onChange(updated)
    }

    render() {
        const {filters, tags, types, content} = this.props;
        return (
            <div className="TagFilter">
                {map(filters, (filter, index) => <FilterRow key={index} filter={filter} tags={tags} onChange={(filter) => this.updateRow(filter, index)}/>)}
                <Row>
                  <Flex/>
                {size(tags) > 0 && <Button onClick={this.addNewRow}>Add a filter</Button>}
                </Row>
            </div>
        )
    }
}

export default TagViewFilter;
