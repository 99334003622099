// @flow

import './CreateSegment.scss'
import * as React from 'react'
import type { History } from 'react-router-dom'
import type { UploadTask } from 'firebase'
import { Button, Flex } from '@upgrowth/react-fulcrum'
import { firekit } from '@upgrowth/firekit'
import { withParams } from '@upgrowth/reactkit'
import SimpleWizard from '../SimpleWizard'
import Card from '../../components/Card'
import { capitalize, cloneDeep, find, keys, map, mapValues, omit, omitBy, without } from 'lodash'
import { TagList } from '../../components/tags/TagList'
import AddTag from '../../components/tags/AddTag'
import { key } from 'firebase-key'
import { resolveRoute } from '../../routes'
import { withProject } from '../../services/withProject'

const predefinedSegments = [
    {
        key: "persona",
        name: "Personas",
        description: "Create a segment of Personas, hypothetical people who represent user or customer segments",
        override: true,
    },
    {
        key: "archetype",
        name: "Archetypes",
        description: "Create a segment of Archetypes, idealised userRole of customers or users",
        override: true,
    },
    {
        key: "cohort",
        name: "Cohort",
        description: "Create a segment based on cohorts, user or customer segments derived from data"
    },
    {key: "custom", name: "Segment", description: "Create another type of segment"},
]
const SegmentOptions = ({value, onChange}) => {
    return (
        <div className="SegmentOptions">
            {map(predefinedSegments, (option) =>
                <Card key={option.key} className={value === option.key ? 'selected' : ''}>
                    <h3>{option.name}</h3>
                    <div>
                        {option.description}
                    </div>
                    <Flex/>
                    <Button type="submit" onClick={() => onChange(option.key)}>Select</Button>
                </Card>)}
        </div>
    )
}

class CreateSegment extends React.Component<{ projectId: string, onComplete: (key: string) => any }, { name?: string, progress?: number, error?: string }> {
    state: {
        working: boolean,
        form: {}

    } = {
        working: false,
        form: {}
    }

    handleSubmit = async (form: any) => {
        const {projectId, onComplete, history} = this.props
        const {database} = firekit()
        this.setState({working: true})
        const ref = await database.ref(`projects/${projectId}/segments`).push(form)
        this.setState({form: {}, working: false})
        const shouldRoute = onComplete(ref.key)
        if(shouldRoute !== false) {
            history.push(resolveRoute('segment', {projectId, segmentId: ref.key}))
        }
    }
    handleNext = async (form: any, page: number) => {
        const {wizardtype} = form
        const match = find(predefinedSegments, {key: wizardtype})
        // Based on the segment type choice, prefill the name
        if (page === 0 && match && match.override) {
            const {name} = match
            this.setState({form: {...form, name}})
        }
    }


    render() {
        const projectTags = this.props.tags
        const {working, form} = this.state
        const {wizardtype, tags = {}, profiles} = form
        const match = find(predefinedSegments, {key: wizardtype})
        const {name}= match || {}
        const value = cloneDeep(form)
        const tagsOnSegment = mapValues(tags, (_, key) => projectTags[key])
        const tagsNotOnSegment = omitBy(projectTags, (value, key) => tags[key] !== undefined)


        const pages = [
            {
                title: "What kind of segment do you want to create?",
                actionLabel: false,
                fields: [
                    {
                        name: "wizardtype",
                        render: (value, onChange) => <SegmentOptions value={value} onChange={onChange}/>,
                        validation: "required"
                    },
                ]
            },
            {
                title: `${name} Details`,
                fields: [
                    {
                        label: "Segment name",
                        name: "name",
                        type: "text",
                        validation: "required",
                        placeholder: `${name} name`,
                        autoComplete: 'off'
                    },
                    {
                        label: "Segment description",
                        name: "description",
                        type: "textarea",
                        placeholder: `Description`,
                    },
                    {

                        name: 'tags',
                        description: `Specify the tags on your ${name} - these are the values that will be present in each profile within this segment.
                        If a tag you need it isn't present, you'll need to configure it on your project, and you can always update them later.`,
                        validation: "required",
                        render: (value, onChange) => <div>
                            <TagList tags={tagsOnSegment} onRemove={(tag)=>onChange(omit(value, tag))}/>
                            <AddTag tags={tagsNotOnSegment} onSelect={(tag)=>onChange({...value, [tag]: ""})}/>
                        </div>
                    },
                ]
            },
            {
                title: `${name} Profiles`,
                description: `Each segment contains multiple named profiles. Here you can create some initial profiles for this segment.`,
                actionLabel: `Create ${name}`,
                fields: [
                    ...map(profiles, (profile, key) => ({
                        name: `profiles.${key}.name`,
                        type: "text",
                        validation: "required",
                        placeholder: `Profile name`,
                        autoComplete: 'off'
                    })),
                    {
                        name: `profiles.${key()}.name`,
                        render: (value, onChange) => <Button onClick={()=>onChange("")}>Add another profile</Button>
                    },
                ]
            }]

        return (
            <div className="CreateSegment">
                <SimpleWizard value={value} pages={pages}
                              onChange={(form) => this.setState({form})}
                              onNext={this.handleNext}
                              onComplete={this.handleSubmit}/>
                {/*<h1>Create Segment</h1>*/}
                {/*<p>Segments are a way of describing the overall types of groups that make up your customer or user base.*/}
                {/*Eg. "Personas" are a way of describing the type of average potential customers.</p>*/}
                {/*<Form onSubmit={this.handleSubmit}*/}
                {/*disabled={working}*/}
                {/*fields={[*/}
                {/*{name: "name", type: "text", validation: "required", placeholder: "Create a new Segment", autoComplete: 'off'},*/}
                {/*]}*/}
                {/*>*/}
                {/*<FormRow>*/}
                {/*<Flex/>*/}
                {/*<Button type="submit">Create New Segment</Button>*/}
                {/*</FormRow>*/}
                {/*</Form>*/}
            </div>
        )
    }

}

export default withProject(CreateSegment)
