// @flow
import * as React from 'react'
import { firekit } from '@upgrowth/firekit'
import * as firebase from 'firebase'

type LoggedInState = {auth?: 'in' | 'out' }

export default class LoggedIn extends React.Component<{children: React.Node, loggedOut?: React.Node, loading?: React.Node}, LoggedInState> {
  state = {}

  componentDidMount() {
    firekit().auth.onAuthStateChanged(this.updateUser)
  }

  updateUser = (user: firebase.User) => this.setState({auth: user ? 'in' : 'out'})

  render() {
    const {auth} = this.state
    const {children, loggedOut = null, loading = null} = this.props

    if (auth === 'in') {
      return children
    }
    if (auth === 'out') {
      return loggedOut
    }

    return loading
  }

}