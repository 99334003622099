// @flow

import * as React from 'react'
import './ScrubBar.scss'
import { toTimeCode } from '../../services/moments'
import playheadIn from './playhead-in.svg'
import playheadOut from './playhead-out.svg'
import playhead from './playhead.svg'

type ScrubCallback = (progress: number) => void

const moveHandler = (onScrub: ScrubCallback) => (
  (event: SyntheticMouseEvent<HTMLDivElement>) => {
    if (event.buttons !== 1) { return }
    event.stopPropagation()
    event.preventDefault()

    const {currentTarget } = event
    const {left} = currentTarget.getBoundingClientRect()
    const {clientWidth} = currentTarget
    const current = (event.clientX - left)/clientWidth
    onScrub(current)
  }
)

type Props = { length: number, current: number, start?: number, end?: number, onScrub: ScrubCallback }


function styleForHead(current, length) {
  const left = Math.max(Math.min(current / length * 100, 100), 0)
  return {left: `${left}%`}
}

const ScrubBar = ({length, current, start, end, onScrub}: Props) => (
  <div className='ScrubBar'>
    <div className='time startTime' onClick={() => onScrub(0)}>
      {toTimeCode(typeof start === 'number' ? start : current)}
    </div>
    <div className='ScrubBar-bar' onMouseMove={moveHandler(onScrub)} onMouseDown={moveHandler(onScrub)}>
      {length !== 0 &&
        <>
          {start !== undefined && <div className='starthead head' style={styleForHead(start, length)}><img src={playheadIn}/></div>}
          <div className='playhead head' style={styleForHead(current, length)}><img src={playhead}/></div>
          {end !== undefined && <div className='endhead head' style={styleForHead(end, length)}><img src={playheadOut}/></div>}
        </>
      }
    </div>
    <div className='time rightTime' onClick={() => onScrub(end || length)}>
      {toTimeCode(typeof end === 'number' ? end : length)}
    </div>
  </div>
)

export default ScrubBar