// @flow

import type { Interview } from '../database'
import { chain, keys, map, mapValues, truncate, values, mapKeys, get, pickBy, isArray, capitalize } from 'lodash'
import type { Entity } from '../elements/tags/Tag'
import XLSX from 'xlsx'
import moment from 'moment'

const interviewAsExportable = ({moments}: Interview, projectTags, tagGroups) => true
/*
chain(moments)
  .map(({tags = {}, transcript, insight}: Moment, momentId: string) => ({
    transcript, insight, ...humanizeTags(tags, projectTags, tagGroups)
  }))
  .values()
  .value()
  */

export type ExportType = "xlsx" | "csv"

const convertEntityToRow = (item, tags, columnsToShow)=> chain(item)
  .pick(['name', 'description', ...columnsToShow]) // filter out any unselected columns
  .mapKeys((value, key) => get(tags, [key, 'name'], capitalize(key)))
  .mapValues((value) => isArray(value) ? value.join(', ') : value)
  .value()

export const exportEntities = async (items: Array<Entity>, tags: {}, columns: { [string]: boolean }, name: string = "", type: ExportType) => {
  const columnsToShow = chain(columns).pickBy().keys().value()
  const rows = chain(items)
    .map(({name, description, tags = {}}) => ({...tags, name, description})) // filter out everything but name, desc and tags
    .map((item) => convertEntityToRow(item, tags, columnsToShow)) // convert keys to names and filter out hidden columns
    .value()

  generateAndDownloadSpreadsheet(name, type, rows)

}

function generateAndDownloadSpreadsheet(name: string, type: ExportType, rows: Array<{}>){
  const filename = `Forest ${name} Export- ${moment().format('YYYY-MM-DD')}.${type}`
  let wb = XLSX.utils.book_new()
  wb.Props = {Title: name}
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(rows), 'Sheet1')
  XLSX.writeFile(wb, filename, {type})
}

export const exportRoundToExcel = async (round: any, roundId: string, projectId: string) => {
  // const filename = `${round.name} - ${moment().format('YYYY-MM-DD')}.${bookType}`
  //
  // const interviewKeys = keys(round.interviews)
  //
  // const interviews = await Promise.all(interviewKeys.map(async researchId =>
  //   await getDB(`interviews/${researchId}`)
  // ))
  //
  // const {tagGroups, tags} = await getDB(`projects/${projectId}`)
  //
  //
  // // TODO
  //
  // const sheetNames = interviewKeys.map(researchId => truncate(round.interviews[researchId], {length: 30}))
  //
  // let wb = XLSX.utils.book_new()
  // wb.Props = {Title: round.name}
  //
  // interviews.forEach((interview: Interview, index) => (
  //   XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(interviewAsExportable(interview, tags, tagGroups)), sheetNames[index])
  // ))
  //
  // XLSX.writeFile(wb, filename, {bookType})
  // track.event('Export to Excel', {category: 'Rounds', label: projectId})
}

