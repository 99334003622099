// @flow

import * as React from 'react'
import { withParams } from '@upgrowth/reactkit'
import { map, trim } from 'lodash'
import { Select } from '@upgrowth/react-fulcrum'
import type { Tag } from './Tag'

export type SelectTag = {
    options: string[]
} & Tag

class SelectTagEditor extends React.Component<*> {
    prop: {
        tag: Tag,
        value: any,
        onChange: (value: any) => any,
    }

    render() {
        const {value, onChange, tag = {}, ...props} = this.props
        const options = map(tag.options, (label, index) => ({label, value: label}))

        return <Select value={value} onChange={onChange} options={options} placeholder={`Select a ${tag.name}`} {...props}/>
    }
}


const SelectTagViewer = ({className, tag: { name = "No Name"}, value}: {className: string, tag: TextTag, value: string}) => (
    <h5 className={`SelectTagViewer ${className}`}>{value}</h5>
)

export {SelectTagEditor, SelectTagViewer}
