// @flow

import './ResearchReport.scss'
import * as React from 'react'
import { NavLink, withParams } from '@upgrowth/reactkit'
import { intersection, isEmpty, map, size } from 'lodash'
import TagView from '../../components/tags/TagView'
import Page from '../../compositions/Page'
import { resolveRoute } from '../../routes'
import Loading from '../../components/Loading'
import { deleteMoment, updateMomentTag } from '../../services/moments'
import { withProject } from '../../services/withProject'
import { hasEditorRole } from '../../roles'

class ResearchReport extends React.Component<*> {
    props: {
        projectId: string,
        moments: {},
        history: any
    };
    state: {
        working: boolean,
    } = {
        working: false,
    };

    tagMoment = async (momentId, tag, value) => {
        const {projectId, moments} = this.props;
        const {researchId} = moments[momentId]
        console.log("Change ", momentId, researchId, projectId, tag, value)
        await updateMomentTag(momentId, researchId, projectId, tag, value)
    }

    viewMoment = (momentId: string) => {
        const {history, projectId, moments} = this.props
        const {researchId} = moments[momentId]
        history.push(resolveRoute('moment', {projectId, researchId, momentId}))
    }

    deleteMoment = async (momentId) => {
        const {projectId, moments} = this.props
        const {researchId} = moments[momentId]
        if (window.confirm("Are you sure you want to delete this moment?")) {
            await deleteMoment(momentId, researchId, projectId)
        }
    }

    render() {
        const {moments = {}, tags = {},  loading, userRole} = this.props;
        const {working} = this.state;
        const editable = hasEditorRole(userRole)

        return (
            <Page className={"ResearchReport"}>
                {loading ? <Loading/> :
                    <TagView
                        className="inset"
                        tags={tags}
                        items={moments}
                        onClick={this.viewMoment}
                        onChange={editable && this.tagMoment}
                        name="Moments"
                        rowActions={ editable && [
                                {label: "View", action: this.viewMoment},
                                {label: "Delete", action: this.deleteMoment},
                            ]
                        }
                    />
                }
            </Page>
        )
    }
}

export default withProject(ResearchReport)
