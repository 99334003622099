// @flow

import "./MomentCoordinates.scss"
import * as React from 'react'
import { toTimeCode } from '../../services/moments'
import type { Moment } from '../../database'

const MomentCoordinates = ({moment}: {moment: Moment}) => {
  if (!moment) {
    return null
  }

  const {timeStart, timeEnd} = moment
  return (
    <div className='MomentCoordinates'>
      {toTimeCode(timeStart)} - {toTimeCode(timeEnd)}
    </div>
  )
}
export default MomentCoordinates