// @flow

import { firekit } from '@upgrowth/firekit'
import { chain, flatMap, forEach, groupBy, isEmpty, keys, map, mapKeys, mapValues } from 'lodash'
import { encode } from 'firebase-key'
import track from './tracking/track'

export async function createProject(project: {details: {name: string}}): Promise<string> {

    const database = firekit().database
    const {uid, displayName} = firekit().auth.currentUser
    project = {...project, users: {[uid]: displayName}}
    const ref = database.ref('/projects').push()
    const name = project.details.name

    track.event('Create', {category: 'Projects', label: ref.key})

    await database.ref('/').update({
        [`/projects/${ref.key}`]: project,
        [`/users/projects/${uid}/${ref.key}`]: name,
        [`/roles/${ref.key}/${uid}`]: 'admin',
    })
    return ref.key
}

export const inviteUser = (email, projectId, projectName) => {
    const encodedEmail = encode(email)
    track.event('Create', {category: 'Invites', label: projectId})

    return firekit().database.ref()
        .update({
            [`invites/${encodedEmail}/${projectId}`]: projectName,
            [`projects/${projectId}/invites/${encodedEmail}`]: email
        })
}

export const acceptInvites = (invites: {}) => {
    const {email, uid, displayName} = firekit().auth.currentUser
    const encodedEmail = encode(email)
    let updates = {}

    forEach(invites, (projectName, projectId) => {
        updates[`invites/${encodedEmail}/${projectId}`] = null
        updates[`projects/${projectId}/invites/${encodedEmail}`] = null
        updates[`projects/${projectId}/users/${uid}`] = displayName
        updates[`users/projects/${uid}/${projectId}`] = projectName
        updates[`roles/${projectId}/${uid}`] = 'editor'

        track.event('Accept', {category: 'Invites', label: projectId})
    })

    return firekit().database.ref().update(updates)
}

export const cancelInvite = (email: string, projectId: string) => {
    const encodedEmail = encode(email)
    track.event('Delete', {category: 'Invites', label: projectId})

    return firekit().database.ref().update({
        [`invites/${encodedEmail}/${projectId}`]: null,
        [`projects/${projectId}/invites/${encodedEmail}`]: null
    })
}

export const rejectInvites = (invites: {}) => {
    const {email} = firekit().auth.currentUser
    const encodedEmail = encode(email)
    let updates = {}

    forEach(invites, (invite, projectId) => {
        updates[`invites/${encodedEmail}/${projectId}`] = null
        updates[`projects/${projectId}/invites/${encodedEmail}`] = null
        track.event('Reject', {category: 'Invites', label: projectId})

    })

    firekit().database.ref().update(updates)
}

export const ejectUser = (uid: string, projectId: string) => {
    track.event('Eject User', {category: 'Projects', label: projectId})

    const updates = {
        [`users/projects/${uid}/${projectId}`]: null,
        [`projects/${projectId}/users/${uid}`]: null
    }
    console.log("Ejecting", updates)
    firekit().database.ref().update(updates)
}