// @flow

import './User.scss'
import * as React from 'react'
import SiteSection from '../../compositions/SiteSection'
import Page from '../../compositions/Page'
import { firekit } from '@upgrowth/firekit'
import { logOut } from '../../services/users'
import { Button } from '@upgrowth/react-fulcrum'

export default () =>{
    const {currentUser = {}} = firekit().auth
    return (
        <SiteSection className={"User"}>
            <Page size="small">
                <h1>{currentUser.displayName}</h1>

                <div>
                    You're currently logged in as {currentUser.email}
                </div>

                <Button onClick={logOut}>Log out</Button>
            </Page>
        </SiteSection>
    )
}